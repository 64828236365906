import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { NexoyaContentFilterInput, NexoyaCreateImpactGroupRuleAndDiscoverContentsMutationResponse } from '../../types';
import { IMPACT_GROUP_RULES_QUERY } from './queryImpactGroupRules';
import { useTeam } from '../../context/TeamProvider';

const CREATE_IMPACT_GROUP_RULE_MUTATION = gql`
  mutation CreateImpactGroupRule(
    $filters: ImpactGroupRuleFiltersInput!
    $name: String!
    $portfolioId: Float!
    $teamId: Float!
    $impactGroupId: Float!
  ) {
    createImpactGroupRuleAndDiscoverContents(
      filters: $filters
      name: $name
      portfolioId: $portfolioId
      teamId: $teamId
      impactGroupId: $impactGroupId
    ) {
      mappedContentsCount
      impactGroupRule {
        impactGroupId
        name
      }
      clashingDiscoveredContents {
        contentRules {
          isApplied
          contentRule {
            contentRuleId
            name
          }
        }
        impactGroupRules {
          isApplied
          impactGroupRule {
            impactGroupId
            impactGroupRuleId
            name
          }
        }
        content {
          contentId
          title
        }
        discoveredContentId
        status
        contentRules {
          isApplied
          contentRule {
            contentRuleId
            name
          }
        }
      }
    }
  }
`;

type CreateImpactGroupRuleProps = {
  name: string;
  teamId: number;
  portfolioId: number;
  providerId: number;
  adAccountId?: number;
  impactGroupId: number;
  filters: NexoyaContentFilterInput[];
};

export function useCreateImpactGroupRuleMutation({
  portfolioId,
  onCompleted,
}: {
  portfolioId: number;
  onCompleted?: (data: {
    createImpactGroupRuleAndDiscoverContents: NexoyaCreateImpactGroupRuleAndDiscoverContentsMutationResponse;
  }) => void;
}) {
  const { teamId } = useTeam();

  return useMutation<
    { createImpactGroupRuleAndDiscoverContents: NexoyaCreateImpactGroupRuleAndDiscoverContentsMutationResponse },
    CreateImpactGroupRuleProps
  >(CREATE_IMPACT_GROUP_RULE_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      toast.success(
        `Impact group created with ${data.createImpactGroupRuleAndDiscoverContents.mappedContentsCount} mapped contents`,
      );
      onCompleted?.(data);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: IMPACT_GROUP_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components-ui/AlertDialog';
import React, { useState } from 'react';
import { LabelLight } from '../../../../components/InputLabel/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import ButtonAsync from '../../../../components/ButtonAsync';
import { NexoyaPortfolioContentDetail } from '../../../../types';
import SvgWarningTwo from '../../../../components/icons/WarningTwo';
import { toast } from 'sonner';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  item: NexoyaPortfolioContentDetail;
  onConfirm: (rules: { contentRuleId: number; impactGroupRuleId: number }) => Promise<void>;
  loading: boolean;
}

export const AssignRulesDialog = ({ isOpen, onClose, item, onConfirm, loading }: Props) => {
  const [selectedContentRule, setSelectedContentRule] = useState(null);
  const [selectedImpactRule, setSelectedImpactRule] = useState(null);
  const [showConfirmPartial, setShowConfirmPartial] = useState(false);

  const handleConfirm = async () => {
    try {
      await onConfirm({
        contentRuleId: parseInt(selectedContentRule),
        impactGroupRuleId: parseInt(selectedImpactRule),
      });
      toast.success('Selected rules successfully applied to content');
    } catch (error) {
      console.error('Error applying rules:', error);
    } finally {
      if (selectedContentRule && !selectedImpactRule) {
        setShowConfirmPartial(true);
      } else {
        onClose();
        setSelectedContentRule(null);
        setSelectedImpactRule(null);
        setShowConfirmPartial(false);
      }
    }
  };

  const renderPartialConfirmContent = () => (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle>Content rule successfully applied to content</AlertDialogTitle>

        <div className="!mt-6 mb-3 flex flex-col rounded-md border border-neutral-100 bg-neutral-50 p-3">
          <div className="flex gap-2">
            <SvgWarningTwo warningCircleColor="#FCF1BA" warningColor="#F5CF0F" style={{ height: 20, width: 20 }} />
            <span className="text-md leading-5 text-neutral-800">Impact group not assigned</span>
          </div>
          <span className="ml-7 font-normal leading-5 text-neutral-700">
            The current impact group rule has been removed from this content. You can reassign rules in{' '}
            <span className="font-medium">Portfolio settings {'>'} Unapplied rules.</span>
          </span>
        </div>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogAction className="w-full">
          <ButtonAsync
            className="!w-full"
            onClick={() => onClose()}
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
          >
            Got it
          </ButtonAsync>
        </AlertDialogAction>
      </AlertDialogFooter>
    </>
  );

  const renderMainContent = () => (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle>Assign rules to content</AlertDialogTitle>
        <AlertDialogDescription>
          <span className="font-normal text-neutral-400">
            Assign a content rule and an impact group rule to this content. These rules will override its existing
            metrics and impact group.
          </span>
          <div className="mb-[-24px] mt-6 flex flex-col rounded-md border border-neutral-100 bg-neutral-50 p-3">
            <div className="flex gap-2">
              <div>
                <SvgWarningTwo warningCircleColor="#FCF1BA" warningColor="#F5CF0F" style={{ height: 24, width: 24 }} />
              </div>
              <span className="text-md font-light leading-5 text-neutral-600">
                Your selection below, including unselected rules, will override the existing content configuration.
                You'll be able to reassign unselected rules later in{' '}
                <span className="font-medium">Portfolio settings {'>'} Unapplied rules</span>
              </span>
            </div>
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>

      <div className="flex flex-col gap-4 py-4">
        <div className="flex flex-col gap-2">
          <LabelLight className="!mb-0 px-0 font-semibold">Content rule metrics</LabelLight>
          <Select
            disabled={!item.discoveredContent?.contentRules?.length || loading}
            value={selectedContentRule || undefined}
            onValueChange={setSelectedContentRule}
          >
            <SelectTrigger className="w-full border-neutral-100 bg-transparent p-2">
              <SelectValue placeholder="Select content rule metrics" />
            </SelectTrigger>
            <SelectContent>
              {item.discoveredContent?.contentRules?.map((cr) => (
                <SelectItem key={cr.contentRule.contentRuleId} value={cr.contentRule.contentRuleId.toString()}>
                  {cr.contentRule.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col gap-2">
          <LabelLight className="!mb-0 px-0 font-semibold">Impact group rule</LabelLight>
          <Select
            disabled={!item.discoveredContent?.impactGroupRules?.length || loading}
            value={selectedImpactRule || undefined}
            onValueChange={setSelectedImpactRule}
          >
            <SelectTrigger className="w-full border-neutral-100 bg-transparent p-2">
              <SelectValue placeholder="Select impact group rule" />
            </SelectTrigger>
            <SelectContent>
              {item.discoveredContent?.impactGroupRules?.map((igr) => (
                <SelectItem
                  key={igr.impactGroupRule.impactGroupRuleId}
                  value={igr.impactGroupRule.impactGroupRuleId.toString()}
                >
                  {igr.impactGroupRule.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <AlertDialogFooter>
        <AlertDialogAction>
          <ButtonAsync disabled={loading} onClick={onClose} variant="contained" color="secondary" size="small">
            Cancel
          </ButtonAsync>
        </AlertDialogAction>
        <AlertDialogAction>
          <ButtonAsync
            disabled={loading || !selectedContentRule}
            onClick={handleConfirm}
            loading={loading}
            variant="contained"
            color="primary"
            size="small"
          >
            Apply rules to content
          </ButtonAsync>
        </AlertDialogAction>
      </AlertDialogFooter>
    </>
  );

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="min-w-[600px]">
        {showConfirmPartial ? renderPartialConfirmContent() : renderMainContent()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

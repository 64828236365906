import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import DOMPurify from 'dompurify';

import { NexoyaPortfolioLabel, NexoyaPortfolioV2 } from '../types';
import { useTeam } from '../context/TeamProvider';
import { useDeleteLabel } from '../graphql/labels/mutationDeleteLabel';
import { useCreateOrUpdateLabel } from '../graphql/labels/mutationCreateLabel';
import { ExtendedLabel } from '../routes/portfolio/components/Labels/LabelsEditTable';
import { useLabelsQuery } from '../graphql/labels/queryLabels';

const CONSTRAINT_ERROR_MESSAGE_INCLUDES = 'constraint fails';

interface Props {
  portfolioMeta: NexoyaPortfolioV2;
  initialLabels?: NexoyaPortfolioLabel[];
}

export const useLocalLabels = ({ portfolioMeta, initialLabels = [] }: Props) => {
  const { teamId } = useTeam();
  const portfolioId = portfolioMeta.portfolioId;
  const { data: labelsData } = useLabelsQuery(portfolioId);

  const [labels, setLabels] = useState<Partial<ExtendedLabel>[]>([]);
  const [pendingChanges, setPendingChanges] = useState<{
    toCreate: Partial<ExtendedLabel>[];
    toUpdate: Partial<ExtendedLabel>[];
    toDelete: number[];
  }>({
    toCreate: [],
    toUpdate: [],
    toDelete: [],
  });

  useEffect(() => {
    // Define a function to update labels to avoid inline function creation
    const updateLabels = () => {
      const newLabels = [...(labelsData?.portfolioV2?.labels || initialLabels)];
      // sort labels by name
      newLabels.sort((a, b) => a.name.localeCompare(b.name));
      setLabels(newLabels);
    };

    updateLabels();
  }, [labelsData]);

  const [deleteLabel] = useDeleteLabel({
    portfolioId,
    labelId: null,
  });

  const [createOrUpdateLabel] = useCreateOrUpdateLabel({
    portfolioId,
    name: null,
    labelId: null,
  });

  const handleEdit = (label: ExtendedLabel) => {
    const isNewLabel = label.labelId < 0;

    if (isNewLabel) {
      setPendingChanges((prev) => ({
        ...prev,
        toCreate: [...prev.toCreate, label],
      }));
      setLabels((prevState) => [...prevState, label]);
    } else {
      setPendingChanges((prev) => ({
        ...prev,
        toUpdate: [...prev.toUpdate, label],
      }));
      setLabels((prevState) => {
        return prevState.map((prevLabel) => {
          if (prevLabel.labelId === label.labelId) {
            return { ...prevLabel, isEditing: !prevLabel.isEditing };
          }
          return prevLabel;
        });
      });
    }
  };

  const handleDelete = (labelId: number) => {
    if (labelId < 0) {
      setPendingChanges((prev) => ({
        ...prev,
        toCreate: prev.toCreate.filter((label) => label.labelId !== labelId),
      }));
      setLabels((prevState) => prevState.filter((label) => label.labelId !== labelId));
      return;
    }

    setPendingChanges((prev) => ({
      ...prev,
      toDelete: [...prev.toDelete, labelId],
    }));
    setLabels((prevState) => prevState.filter((label) => label.labelId !== labelId));
  };

  const resetLabels = () => {
    setLabels(labelsData?.portfolioV2?.labels || []);
    setPendingChanges({
      toCreate: [],
      toUpdate: [],
      toDelete: [],
    });
  };

  const commitChanges = async () => {
    try {
      // Handle creations
      for (const label of pendingChanges.toCreate) {
        await createOrUpdateLabel({
          variables: {
            name: DOMPurify.sanitize(label.name, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }),
            portfolioId,
            teamId,
            labelId: null,
          },
        });
      }

      // Handle updates
      for (const label of pendingChanges.toUpdate) {
        await createOrUpdateLabel({
          variables: {
            name: DOMPurify.sanitize(label.name, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }),
            portfolioId,
            teamId,
            labelId: label.labelId,
          },
        });
      }

      // Handle deletions
      for (const labelId of pendingChanges.toDelete) {
        try {
          await deleteLabel({
            variables: {
              labelId,
              portfolioId,
              teamId,
            },
          });
        } catch (error) {
          if (error.message.includes(CONSTRAINT_ERROR_MESSAGE_INCLUDES)) {
            toast.error('The label is currently linked to content and cannot be removed');
          } else {
            toast.error(error.message);
          }
          // Restore the label in the UI if deletion fails
          const labelToRestore = labelsData?.portfolioV2?.labels.find((l) => l.labelId === labelId);
          if (labelToRestore) {
            setLabels((prev) => [...prev, labelToRestore]);
          }
        }
      }

      // Reset pending changes after successful commit
      setPendingChanges({
        toCreate: [],
        toUpdate: [],
        toDelete: [],
      });
    } catch (error) {
      toast.error('Failed to save label changes');
      throw error;
    }
  };

  return {
    labels,
    setLabels,
    handleEdit,
    handleDelete,
    resetLabels,
    commitChanges,
    hasPendingChanges:
      pendingChanges.toCreate.length > 0 || pendingChanges.toUpdate.length > 0 || pendingChanges.toDelete.length > 0,
  };
};

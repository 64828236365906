import React, { Dispatch, JSX, SetStateAction, useEffect, useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../../../components-ui/Select';
import { LabelLight } from '../../../../../../components/InputLabel/styles';
import { Input } from '../../../../../../components-ui/Input';
import { Button } from '../../../../../../components-ui/Button';
import { CirclePlus, Trash2 } from 'lucide-react';
import { useFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/queryFunnelStepMappingPreset';
import { useRouteMatch } from 'react-router';
import {
  NexoyaFunnelStepMappingInput,
  NexoyaFunnelStepMappingPreset,
  NexoyaFunnelStepMappingType,
  NexoyaFunnelStepUtmMappingParams,
} from '../../../../../../types';
import { useSaveFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/mutationSaveFunnelStepMappingPreset';
import { useTeam } from '../../../../../../context/TeamProvider';
import { useFunnelStepUtmMapping } from '../../../../../../graphql/portfolio/queryFunnelStepUtmMapping';
import { useUpdateFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/mutationUpdateFunnelStepMappingPreset';
import { toast } from 'sonner';
import { camelCaseToWords } from '../utils';
import { useListFunnelStepUtmVariables } from '../../../../../../graphql/portfolioRules/queryUTMVariables';

type AssignedMappingExtended = NexoyaFunnelStepMappingInput & {
  funnelStepId?: number;
  presetName?: string;
};

export const UTMTracking = ({
  assignedMetrics,
  setAssignedMetrics,
  renderAssignedMetricCombobox,
  selectedMetricId,
  funnelStepId,
}: {
  assignedMetrics: AssignedMappingExtended[];
  setAssignedMetrics: Dispatch<SetStateAction<AssignedMappingExtended[]>>;
  renderAssignedMetricCombobox: (triggerClassName?: string) => JSX.Element;
  selectedMetricId: number;
  funnelStepId: number;
}) => {
  const { teamId } = useTeam();
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const [openSuggestionIndex, setOpenSuggestionIndex] = useState<number | null>(null);
  const [openValueIndex, setOpenValueIndex] = useState<number | null>(null);
  const { data: funnelStepUtmVariablesData } = useListFunnelStepUtmVariables();
  const utmVariables = funnelStepUtmVariablesData?.listFunnelStepUtmExpansionVariables;

  const { data: funnelStepsUtmMappingData } = useFunnelStepUtmMapping();
  const utmTrackingOptions: NexoyaFunnelStepUtmMappingParams[] =
    funnelStepsUtmMappingData?.listFunnelStepUtmMappingParams?.map((param: NexoyaFunnelStepUtmMappingParams) => ({
      name: camelCaseToWords(param.name),
      type: param.type,
    })) || [];

  const [saveFunnelStepMappingPreset, { loading: loadingSavePreset }] = useSaveFunnelStepMappingPreset({ portfolioId });
  const [updateFunnelStepMappingPreset] = useUpdateFunnelStepMappingPreset({
    portfolioId,
  });

  const { data: funnelStepPresetsData } = useFunnelStepMappingPreset();

  const mappingPresets: NexoyaFunnelStepMappingPreset[] = funnelStepPresetsData?.listFunnelStepMappingPresets || [];

  // Ensure we have an entry in assignedMetrics for this funnelStepId.
  // If not, create one with defaults.
  useEffect(() => {
    setAssignedMetrics((prev) => {
      const index = prev.findIndex((m) => m.funnelStepId === funnelStepId);
      if (index === -1) {
        // Create a new entry with defaults
        return [
          ...prev,
          {
            type: prev[index].type,
            funnelStepId,
            metricId: selectedMetricId,
            conversions: [],
            utmParams: [],
            presetName: '',
          },
        ];
      }
      return prev;
    });
  }, [funnelStepId, selectedMetricId, setAssignedMetrics]);

  useEffect(() => {
    if (utmTrackingOptions && !utmParams.length) {
      handleAddParam();
    }
  }, [funnelStepId]);

  // Helper function to update the assignedMetrics for this funnelStepId
  const updateAssignedMapping = (partial: Partial<AssignedMappingExtended>) => {
    setAssignedMetrics((prev) => {
      const index = prev.findIndex((m) => m.funnelStepId === funnelStepId);
      if (index === -1) return prev; // should never happen since we ensure an entry above
      const newMetrics = [...prev];
      newMetrics[index] = { ...newMetrics[index], ...partial };
      return newMetrics;
    });
  };

  // Derived values from assignedMetrics
  const currentMappingIndex = assignedMetrics.findIndex((m) => m.funnelStepId === funnelStepId);
  const currentMapping = currentMappingIndex !== -1 ? assignedMetrics[currentMappingIndex] : undefined;

  const utmParams = currentMapping?.utmParams || [];
  const presetName = currentMapping?.presetName || '';

  const handleAddParam = () => {
    if (utmParams.length < 5) {
      const newParams = [...utmParams, { type: '', values: [''] }];
      updateAssignedMapping({ utmParams: newParams });
    }
  };

  const handleAddValue = (paramIndex: number) => {
    const newParams = utmParams.map((p, i) => {
      if (i === paramIndex) {
        return {
          ...p,
          values: [...p.values, ''],
        };
      }
      return p;
    });
    updateAssignedMapping({ utmParams: newParams });
  };

  const handleDeleteValue = (paramIndex: number, valueIndex: number) => {
    if (utmParams[paramIndex].values.length <= 1) {
      // Delete the entire dimension when only one value is left
      const newParams = utmParams.filter((_, i) => i !== paramIndex);
      updateAssignedMapping({ utmParams: newParams });
      return;
    }

    const newParams = utmParams.map((p, i) => {
      if (i === paramIndex) {
        return {
          ...p,
          values: p.values.filter((_, vi) => vi !== valueIndex),
        };
      }
      return p;
    });
    updateAssignedMapping({ utmParams: newParams });
  };

  const handleUpdateParam = (paramIndex: number, valueIndex: number, key: 'type' | 'value', value: string) => {
    // Check if {} was just typed
    if (key === 'value' && value.endsWith('{}')) {
      setOpenSuggestionIndex(paramIndex);
      setOpenValueIndex(valueIndex);
    } else if (key === 'value') {
      setOpenSuggestionIndex(null);
      setOpenValueIndex(null);
    }

    const newParams = utmParams.map((p, i) => {
      if (i === paramIndex) {
        if (key === 'type') {
          return { ...p, type: value };
        } else {
          const newValues = [...p.values];
          newValues[valueIndex] = value;
          return { ...p, values: newValues };
        }
      }
      return p;
    });
    updateAssignedMapping({ utmParams: newParams });
  };

  const handleSuggestionSelect = (selectedOption: string, value: string, paramIndex: number, valueIndex: number) => {
    const newValue = value.replace('{}', selectedOption);
    handleUpdateParam(paramIndex, valueIndex, 'value', newValue);
    setOpenSuggestionIndex(null);
    setOpenValueIndex(null);
  };
  const handleSelectPreset = (id: string) => {
    const selectedPreset = mappingPresets.find((preset) => preset.funnelStepMappingPresetId.toString() === id);
    if (selectedPreset) {
      updateAssignedMapping({
        metricId: selectedPreset.mapping?.metricId,
        presetName: selectedPreset.name || '',
        // @ts-ignore
        utmParams: selectedPreset.mapping?.utmParams,
      });
    }
  };

  const handleSaveConfiguration = () => {
    if (!presetName.trim() || !selectedMetricId) return;

    // Check if preset already exists
    const existingPreset = mappingPresets.find((p) => p.name.toLowerCase() === presetName.trim().toLowerCase());

    // Build mapping from current assignedMetrics
    const mappedParams = utmParams.filter((p) => p.type && p.type.trim() && p.values.some((v) => v.trim()));

    // if there is no p.type and p.value, send a toast requesting the UTM params
    if (mappedParams.length === 0) {
      toast.warning('Please fill in at least one UTM parameter with both Parameter type and Parameter value.');
      return;
    }

    const mapping: NexoyaFunnelStepMappingInput = {
      metricId: selectedMetricId,
      type: NexoyaFunnelStepMappingType.Utm,
      utmParams: mappedParams.map((p) => ({
        type: p.type,
        values: p.values,
      })),
    };

    updateAssignedMapping({ presetName }); // store presetName in assignedMetrics

    if (existingPreset) {
      updateFunnelStepMappingPreset({
        variables: {
          teamId,
          name: presetName,
          mapping,
          funnelStepMappingPresetId: existingPreset.funnelStepMappingPresetId,
        },
      });
    } else {
      saveFunnelStepMappingPreset({
        variables: {
          teamId,
          name: presetName,
          mapping,
        },
      });
    }
  };

  return (
    <div className="flex max-w-[526px] flex-col gap-6">
      <div className="flex flex-col gap-1">
        <div className="mt-3 text-lg text-neutral-700">GA4 Tracking</div>
        <div className="mt-0.5 max-w-lg text-sm font-light text-neutral-400">
          Use an existing GA4 Tracking configuration or create a new configuration for this funnel step.
        </div>
        <div className="mt-4">
          <LabelLight>Saved configurations</LabelLight>
          <Select disabled={!mappingPresets?.length} onValueChange={handleSelectPreset}>
            <SelectTrigger className="max-w-xl border-neutral-100 bg-white p-2 shadow-sm">
              {loadingSavePreset ? (
                'Loading...'
              ) : (
                <SelectValue placeholder={!mappingPresets?.length ? 'No configurations saved' : 'Load configuration'} />
              )}
            </SelectTrigger>
            <SelectContent>
              {mappingPresets.map((config) => (
                <SelectItem key={config.funnelStepMappingPresetId} value={config.funnelStepMappingPresetId.toString()}>
                  <span>{config.name}</span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      {/* UTM Parameters */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="text-md mt-3 font-semibold tracking-normal text-neutral-700">
            Configuration step 1: UTM Parameters
          </div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            Select up to 5 different UTM parameters for this funnel step.
            <br />
            This step is optional.
          </div>
        </div>
        <div>
          {utmParams.map((param, index) => (
            <div key={index} className="mb-4">
              <div className="flex w-full flex-row items-start justify-between gap-3">
                <div className="w-44">
                  <LabelLight style={{ fontSize: 11 }}>Dimension</LabelLight>
                  <Select
                    value={param.type || undefined}
                    onValueChange={(value) => handleUpdateParam(index, 0, 'type', value)}
                  >
                    <SelectTrigger className="max-w-32 whitespace-pre border-neutral-100 bg-white p-2 shadow-sm">
                      <SelectValue placeholder="Select..." />
                    </SelectTrigger>
                    <SelectContent>
                      {utmTrackingOptions.map((option) => (
                        <SelectItem key={option.type} value={option.type}>
                          <span>{option.name}</span>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex w-full flex-col">
                  {param.values.map((value, valueIndex) => (
                    <>
                      <div key={valueIndex} className="flex w-full items-center">
                        <div className="w-full">
                          <LabelLight style={{ fontSize: 11 }}>Parameter value {valueIndex + 1}</LabelLight>
                          <div className="flex w-full items-center gap-2">
                            <Select
                              open={openSuggestionIndex === index && openValueIndex === valueIndex}
                              onValueChange={(val) => handleSuggestionSelect(val, value, index, valueIndex)}
                            >
                              <Input
                                className="relative w-full shadow-sm"
                                placeholder="Enter value"
                                value={value}
                                onChange={(e) => handleUpdateParam(index, valueIndex, 'value', e.target.value)}
                              />
                              <SelectTrigger className="h-0 w-0 border-none p-0 opacity-0" />
                              <SelectContent className="absolute left-[-345px] top-4">
                                {utmVariables?.map((variable) => (
                                  <SelectItem key={variable} value={variable}>
                                    {`{${variable}}`}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>

                            <Button
                              size="icon"
                              variant="ghost"
                              onClick={() => handleDeleteValue(index, valueIndex)}
                              disabled={index === 0 ? valueIndex === 0 : false}
                            >
                              <Trash2 className="h-5 w-5 text-neutral-300" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      {valueIndex !== param?.values?.length - 1 && (
                        <div className="my-4 flex items-center gap-2">
                          <div className="h-[1px] w-6 border-t-2 border-dotted border-neutral-100"></div>
                          <span className="text-xs text-neutral-400">OR</span>
                          <div className="h-[1px] flex-1 border-t-2 border-dotted border-neutral-100"></div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              {/*<span className="ml-32 text-[10px] text-neutral-300">Hint: Type "{'{}'}" to insert a variable.</span>*/}
              <span onClick={() => handleAddValue(index)} className="ml-36 cursor-pointer text-[10px] text-neutral-300">
                + <span className="underline">Add new value</span>
              </span>
              {index !== utmParams.length - 1 && (
                <div className="my-4 flex items-center gap-2">
                  <div className="h-[1px] w-6 border-t-2 border-dotted border-neutral-100"></div>
                  <span className="text-xs text-neutral-400">AND</span>
                  <div className="h-[1px] flex-1 border-t-2 border-dotted border-neutral-100"></div>
                </div>
              )}
            </div>
          ))}
          <div className="flex items-center gap-4">
            <div className="h-[1px] w-full bg-neutral-100"></div>
            <Button onClick={handleAddParam} size="icon" variant="ghost">
              <CirclePlus className="h-5 w-5 text-neutral-300" />
            </Button>
          </div>
        </div>
      </div>
      {/* Metric Type */}
      <div className="flex max-w-[526px] flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="text-md font-semibold tracking-normal text-neutral-700">
            Configuration step 2: Metric Type
          </div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            Select one metric type for this funnel step.
            <br />
            This step is mandatory.
          </div>
        </div>
        <div className="flex items-end gap-4">
          <div className="w-full">
            <LabelLight>Metric</LabelLight>
            {renderAssignedMetricCombobox('w-full')}
          </div>
        </div>
      </div>
      {/* Save Configuration */}
      <div className="mb-40 flex max-w-[526px] flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="text-md font-semibold tracking-normal text-neutral-700">Save GA4 Tracking configuration</div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            You can save your configuration above and give it a name for future use.
          </div>
        </div>
        <div className="flex items-end gap-4">
          <div className="w-full">
            <Input
              className="shadow-sm"
              placeholder="Configuration name"
              value={presetName}
              onChange={(e) => updateAssignedMapping({ presetName: e.target.value })}
            />
          </div>
          <Button
            disabled={!presetName || !selectedMetricId || !utmParams.some((u) => u.type && u.values.some((v) => v))}
            variant="secondary"
            onClick={handleSaveConfiguration}
          >
            Save configuration
          </Button>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useMemo, useState } from 'react';

import { get, uniq } from 'lodash';
import styled, { css } from 'styled-components';

import { NexoyaCollection, NexoyaPortfolioContentDetail, PortfolioCollectionContent } from 'types';

import { usePortfolio } from '../../../context/PortfolioProvider';
import { usePortfolioQuery } from '../../../graphql/portfolio/queryPortfolio';
import GridWrap from '../../../components/GridWrap';
import SvgKpi from '../../../components/icons/Kpi';
import ContentEdit from '../components/Content/ContentEdit';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import NoDataFound from '../NoDataFound';
import { ExtendedTable } from '../../../components/Table/ExtendedTable';
import { TableStyled } from '../styles/OptimizationProposal';
import { TableManager } from '../../../components/Table/TableManager';
import { getColumns } from '../components/Content/columns';
import { PerformanceHeader } from '../components/PerformanceChartHeader';
import Checkbox from '../../../components/Checkbox';
import { getData } from '../components/Content/data-table';
import TextField from '../../../components/TextField';
import { useLabels } from 'context/LabelsProvider';
import { useSidePanelState } from '../../../components/SidePanel';
import useTeamColor from '../../../hooks/useTeamColor';
import { useFunnelStepsV2Query } from '../../../graphql/funnelSteps/queryFunnelSteps';
import { ACTIONS_HEADER_ID } from '../components/OptimizationProposal/columns';
import { useImpactGroups } from '../../../context/ImpactGroupsProvider';
import { useContentRulesStore } from '../../../store/content-rules';
import { useContentRuleQuery } from '../../../graphql/portfolioRules/queryContentRules';
import usePortfolioFeatureFlag from '../../../components/PortfolioFeatureSwitch/usePortfolioFeatureFlag';
import { PORTFOLIO_FEATURE_FLAGS } from '../../../constants/featureFlags';

type Props = {
  dateFrom: Date;
  dateTo: Date | string;
  portfolioId: number;
};
const GridWrapStyled = styled(GridWrap)`
  .NEXYGridHeader,
  .NEXYCSSGrid {
    padding: 0 24px;
  }
`;

const ContentEditWrapperStyled = styled.div<{ useAbsolutePosition?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  ${({ useAbsolutePosition }) =>
    useAbsolutePosition &&
    css`
      position: absolute;
      top: 16px;
      right: 0;
    `}
`;

export const LoadingWrapStyled = styled.div`
  padding-top: 20px;
  height: 100vh;

  & > div {
    margin-bottom: 15px;
    background: #f4f6f7;
    height: 40px;

    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(2) {
      opacity: 0.75;
    }
    &:nth-child(3) {
      opacity: 0.5;
    }
    &:nth-child(4) {
      opacity: 0.25;
    }
  }
`;

function Content({ dateFrom, dateTo, portfolioId }: Props) {
  const [isMounted, setIsMounted] = useState(false);
  const [hideDisabledContents, setHideDisabledContents] = useState(false);
  const [search, setSearch] = useState('');

  const { isOpen } = useSidePanelState();

  const [mappedContentIds, setMappedContentIds] = useState<PortfolioCollectionContent[]>([]);
  const getThemeColor = useTeamColor();
  const { contentSelection } = usePortfolio();

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const { isFeatureEnabled: hasSelfServicePortfolioFeatureEnabled } = usePortfolioFeatureFlag([
    PORTFOLIO_FEATURE_FLAGS.SELF_SERVICE_PORTFOLIO,
  ]);

  const {
    filter: { contentRulesFilter },
    setContentRules,
  } = useContentRulesStore();

  useContentRuleQuery({
    portfolioId,
    onCompleted: (data) => setContentRules(data?.portfolioV2?.contentRules),
    skip: !hasSelfServicePortfolioFeatureEnabled,
  });

  const { data: funnelStepsData } = useFunnelStepsV2Query({
    portfolioId,
  });

  const {
    providers: { providersFilter },
  } = usePortfolio();

  const {
    filter: { labelsFilter },
  } = useLabels();

  const {
    filter: { impactGroupsFilter },
  } = useImpactGroups();

  const {
    data: legacyPortfolioData,
    loading,
    refetch: refetchLegacyPortfolio,
  } = usePortfolioQuery({
    portfolioId,
    dateFrom,
    dateTo,
    withBudget: false,
  });

  const portfolio = legacyPortfolioData?.portfolio;
  const funnelSteps = funnelStepsData?.portfolioV2?.funnelSteps || [];

  const content: NexoyaPortfolioContentDetail[] | NexoyaCollection[] = useMemo(
    () => get(portfolio, 'content.contentDetails', []) || [],
    [portfolio],
  );
  // repact collection info so it matches the schema returned from
  // collections query
  useEffect(() => {
    contentSelection.reset();
    const preselectedContent = [];
    content.forEach((item) => {
      preselectedContent.push({
        collection_id: item.contentId,
        title: get(item, 'content.title'),
        provider: get(item, 'content.provider'),
        collectionType: item.content?.collectionType,
        portfolioContentId: item.portfolioContentId,
        parent: true,
        childContent: item.childContent
          ? item.childContent.map((childItem) => ({
              collection_id: childItem.contentId,
              title: get(childItem, 'content.title'),
              portfolioContentId: item.portfolioContentId,
              parent: false,
            }))
          : null,
      });
      setMappedContentIds((s) => [
        ...s,
        {
          collection_id: item.contentId,
          portfolio_content_id: item.portfolioContentId,
        },
      ]);
    });
    contentSelection.add(preselectedContent);
    contentSelection.setInitial(preselectedContent);
  }, [portfolio?.content, contentSelection.reset]);

  useEffect(() => {
    setTimeout(() => setIsMounted(true));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault();
        document.getElementById('content-search')?.focus();
      }
    };

    if (!isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const hasLabels = portfolioMeta?.labels?.length > 0;
  const hasBidStrategy = content.some((c) => c?.metadata?.biddingStrategy);
  const hasBudgetLimits = content?.some((c) => c?.budget?.min || c?.budget?.max);

  if (loading) {
    return (
      <LoadingWrapStyled>
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
      </LoadingWrapStyled>
    );
  }

  if (!content.length) {
    return (
      <>
        <ContentEditWrapperStyled useAbsolutePosition={false}>
          <ContentEdit
            portfolio={portfolio}
            refetchPortfolio={refetchLegacyPortfolio}
            dateTo={dateTo}
            dateFrom={dateFrom}
            mappedContentIds={mappedContentIds}
          />
        </ContentEditWrapperStyled>
        <NoDataFound
          icon={<SvgKpi />}
          title="There are no contents assigned in this portfolio."
          subtitle="Please select the 'Edit content' button to add contents. Once you do, you'll see them here."
        />
      </>
    );
  }

  if (loading || !isMounted) {
    return (
      <LoadingWrapStyled>
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
      </LoadingWrapStyled>
    );
  }

  const filteredProviderIds = providersFilter.map((provider) => provider.provider_id);
  const filteredLabelIds = labelsFilter.map((label) => label.labelId);
  const filteredImpactGroupIds = impactGroupsFilter.map((impactGroup) => impactGroup.impactGroupId);
  const filteredContentRuleIds = contentRulesFilter.map((contentRule) => contentRule.contentRuleId);
  const activeProviderIds = uniq(content.map((item) => item.content.provider.provider_id));

  return (
    <>
      <ContentEditWrapperStyled useAbsolutePosition={false}>
        <ContentEdit
          portfolio={portfolio}
          refetchPortfolio={refetchLegacyPortfolio}
          dateTo={dateTo}
          dateFrom={dateFrom}
          mappedContentIds={mappedContentIds}
        />
      </ContentEditWrapperStyled>
      <PerformanceHeader
        disabled={false}
        shouldRenderCustomization={false}
        shouldRenderLabelsFilter
        shouldRenderImpactGroupsFilter
        shouldRenderContentRulesFilter={hasSelfServicePortfolioFeatureEnabled}
        activeProviderIds={activeProviderIds}
        renderAdditionalComponents={() => (
          <TextField
            style={{ width: 304 }}
            wrapProps={{ style: { padding: '6px 12px' } }}
            placeholder="Search contents..."
            value={search}
            name="search"
            id="content-search"
            labelVariant="light"
            onChange={(e) => setSearch(e?.target?.value)}
          />
        )}
        renderSwitcher={() => (
          <Checkbox
            checked={hideDisabledContents}
            onChange={(_: Event, value: boolean) => setHideDisabledContents(value)}
            label="Show disabled contents"
          />
        )}
      />

      {isMounted && !loading ? (
        <TableStyled maxHeight="90vh">
          <ExtendedTable
            tableId="content_table"
            disableManager={false}
            disableExpanded={false}
            disablePagination={false}
            data={getData({
              funnelSteps,
              content,
              filteredProviderIds,
              filteredLabelIds,
              filteredImpactGroupIds,
              filteredContentRuleIds,
              hideDisabledContents,
              search,
              dateTo,
              dateFrom,
              portfolio,
              getThemeColor,
            })}
            columns={getColumns({ funnelSteps, hasLabels, hasBidStrategy, hasBudgetLimits })}
            renderTableManager={({
              columns,
              getToggleHideAllColumnsProps,
              toggleHideAllColumns,
              setStickyColumns,
              stickyColumns,
            }) => (
              <TableManager
                idsNotAllowedToHide={[ACTIONS_HEADER_ID, 'expander', 'content']}
                columns={columns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                toggleHideAllColumns={toggleHideAllColumns}
                setStickyColumns={setStickyColumns}
                stickyColumns={stickyColumns}
                depth={0}
              />
            )}
          />
        </TableStyled>
      ) : null}
    </>
  );
}

export default Content;

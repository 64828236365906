import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { NexoyaImpactGroup, NexoyaPortfolioContentDetail } from 'types';

import { useTeam } from '../../../../context/TeamProvider';
import { ASSIGN_IMPACT_GROUP_TO_PORTFOLIO_CONTENT_MUTATION } from '../../../../graphql/impactGroups/mutationAssignImpactGroupToPortfolioContent';
import { PORTFOLIO_QUERY } from '../../../../graphql/portfolio/queryPortfolio';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';

import Typography from '../../../../components/Typography';
import MenuList from 'components/ArrayMenuList';
import Button from 'components/Button';
import ButtonAdornment from 'components/ButtonAdornment';
import { useDropdownMenu } from 'components/DropdownMenu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import {
  updateApolloCache,
  updatePortfolioContentDetailsDiscoveredContentCache,
  updatePortfolioContentDetailsImpactGroupCache,
} from '../../../../utils/cache';
import { ChevronsUpDown } from 'lucide-react';
import { usePortfolio } from '../../../../context/PortfolioProvider';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../components-ui/AlertDialog';
import Checkbox from '../../../../components/Checkbox';

interface Props {
  content: NexoyaPortfolioContentDetail;
  portfolioId: number;
  dateFrom: Date;
  dateTo: Date | string;
}
export function ContentImpactGroupPanel({ content, portfolioId, dateFrom, dateTo }: Props) {
  const { teamId } = useTeam();
  const anchorElPanel = React.useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);
  const [hideMessage, setHideMessage] = useState(localStorage.getItem('hideMetricChangeMessage') || false);

  const {
    anchorEl: anchorElImpactGroup,
    open: openImpactGroup,
    closeMenu: closeImpactGroupMenu,
    toggleMenu,
  } = useDropdownMenu();

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const impactGroups = portfolioMeta?.impactGroups;
  const isRuleBased = content.discoveredContent?.status !== 'MANUAL';

  const [assignImpactGroupToPortfolioContent, { loading }] = useMutation(
    ASSIGN_IMPACT_GROUP_TO_PORTFOLIO_CONTENT_MUTATION,
  );

  const onClickListener = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      const containsInstance = anchorElPanel.current && anchorElPanel.current.contains(event.target);
      if (containsInstance) {
        return toggleMenu();
      }
      return closeImpactGroupMenu();
    },
    [anchorElPanel, toggleMenu, closeImpactGroupMenu],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', onClickListener);
    return function () {
      document.removeEventListener('mousedown', onClickListener);
    };
  }, []);

  useEffect(() => {
    const savedPreference = localStorage.getItem('hideMetricChangeMessage');
    if (savedPreference) {
      setHideMessage(JSON.parse(savedPreference));
    }
  }, []);

  const handleCheckboxChange = (checked: boolean) => {
    setHideMessage(checked);
    localStorage.setItem('hideMetricChangeMessage', JSON.stringify(checked));
  };

  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction();
    }
    setShowAlert(false);
    setPendingAction(null);
  };

  const handleCancel = () => {
    setShowAlert(false);
    setPendingAction(null);
  };

  const handleImpactGroupChange = (callback: () => void) => {
    if (isRuleBased && !hideMessage) {
      setShowAlert(true);
      setPendingAction(() => callback);
    } else {
      callback();
    }
  };

  function handleAddContentToImpactGroup(impactGroup: NexoyaImpactGroup) {
    const updateCaches = () => {
      assignImpactGroupToPortfolioContent({
        notifyOnNetworkStatusChange: false,
        variables: {
          contentIds: [content.contentId],
          impactGroupId: impactGroup.impactGroupId,
          portfolioId,
          teamId,
        },
      })
        .then(({ data }) => {
          if (data?.assignImpactGroupToPortfolioContents) {
            // Update impact group cache
            updateApolloCache({
              query: PORTFOLIO_QUERY,
              variables: {
                teamId,
                portfolioId,
                dateFrom,
                dateTo,
                withBudget: false,
              },
              updateFn: updatePortfolioContentDetailsImpactGroupCache({
                contentId: content?.contentId,
                impactGroup,
                portfolioId,
              }),
            });

            // Update discovered content status if it's rule-based
            if (isRuleBased) {
              updateApolloCache({
                query: PORTFOLIO_QUERY,
                variables: {
                  teamId,
                  portfolioId,
                  dateFrom,
                  dateTo,
                  withBudget: false,
                },
                updateFn: updatePortfolioContentDetailsDiscoveredContentCache({
                  contentId: content.contentId,
                }),
              });
              toast.info('Content switched to manual mode', {
                description:
                  'The content has been detached from its rules defined in settings. The current configuration has not been affected.',
              });
            }

            closeImpactGroupMenu();
            track(EVENT.CONTENT_CHANGE_IMPACT_GROUP, {
              contentId: content.contentId,
              impactGroupId: impactGroup.impactGroupId,
            });
          }
        })
        .catch((err) => {
          toast(err.message);
          console.error(err);
        });
    };

    handleImpactGroupChange(updateCaches);
  }

  return (
    <>
      <Button
        variant="text"
        color="tertiary"
        flat
        type="button"
        onClick={toggleMenu}
        active={openImpactGroup}
        className="NEXYButtonMetric !rounded-md !px-1.5 !py-0.5 hover:bg-seasalt"
        loading={loading}
        endAdornment={
          <ButtonAdornment position="end">
            <ChevronsUpDown className="h-4 w-4" />
          </ButtonAdornment>
        }
        ref={anchorElImpactGroup}
      >
        {content?.impactGroup?.name}
      </Button>
      <Panel
        open={openImpactGroup}
        color="dark"
        ref={anchorElPanel}
        anchorEl={anchorElImpactGroup.current}
        placement="bottom-start"
        style={{
          minWidth: 200,
          maxHeight: 500,
        }}
      >
        <MenuList color="dark">
          {impactGroups?.map((impactGroup: NexoyaImpactGroup) => (
            <MenuItem
              key={`impactGroup-${impactGroup.impactGroupId}`}
              loading={loading}
              disabled={loading}
              async
              style={{
                minWidth: 125,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleAddContentToImpactGroup(impactGroup);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{impactGroup.name}</Typography>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </Panel>

      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Change impact group manually</AlertDialogTitle>
            <AlertDialogDescription className="!mt-3">
              <span className="font-light text-neutral-400">
                Changing this impact group will switch this content’s mode to “Manual” and will detach it from all rules
                defined in portfolio settings. The current rule configuration will not be affected.
              </span>
            </AlertDialogDescription>
            <div className="text-neutral-400">
              <Checkbox
                label="Don't show this message again"
                className="!pl-0 !font-normal"
                checked={hideMessage}
                onChange={(_, checked: boolean) => handleCheckboxChange(checked)}
              />
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button size="small" variant="contained" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={handleConfirm}>
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

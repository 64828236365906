export const EVENT = {
  DASHBOARD_QUICK_TIP_LEARN_MORE: 'Action: Dashboard: click learn more on tip',
  DASHBOARD_CHANGE_METRIC_DATE_RANGE: 'Action: Dashboard: change metric date range',
  DASHBOARD_CLICK_ON_METRIC: 'Action: Dashboard: click on metric',
  SETTINGS_INVITE_MEMBER: 'Action: Settings: invite member',
  PORTFOLIO_CREATE: 'Action: Portfolio: create new',
  PORTFOLIO_UPDATE: 'Action: Portfolio: update',
  DASHBOARD_PORTFOLIO_CHART_CLICK: 'Action: Click on Portfolio Chart',
  PORTFOLIO_UPDATE_CONTENT: 'Action: Portfolio: update content',
  PORTFOLIO_CONTENT_BUDGET_BOUNDARIES_UPDATE: 'Action: Portfolio: update content budget limits',
  DISABLE_CONTENT_OPTIMIZATION: 'Action: Portfolio: disable content optimization',
  ENABLE_CONTENT_OPTIMIZATION: 'Action: Portfolio: enable content optimization',
  FAVORITE_KPI: 'Action: Kpi: favorite kpi',
  REPORT_KPI_ADD: 'Action: Report: add kpis',
  REPORT_KPI_REMOVE: 'Action: Report: remove kpis',
  REPORT_CREATE_CHANNEL_REPORT: 'Action: Report: create new channel report',
  REPORT_CREATE_KPI_REPORT: 'Action: Report: create new metric report',
  REPORT_UPDATE: 'Action: Report: update',
  REPORT_SHARE: 'Action: Report: share',
  REPORT_DOWNLOAD_PDF: 'Action: Report: pdf download',
  REPORT_DOWNLOAD_XLSX: 'Action: Report: xlsx download',
  METRIC: 'Action: Metric: Change date range',
  CUSTOM_KPI_ADD: 'Action: Custom kpi: add custom kpi',
  EVENT_ADD: 'Action: Event: create new',
  EVENT_UPDATE: 'Action: Event: update',
  FUNNEL_CREATE: 'Action: Funnel: create new',
  FUNNEL_UPDATE: 'Action: Funnel: update',
  NOTIFICATION_CLICK_BELL: 'Action: Notification: click bell icon',
  CORRELATION_LOAD_MORE: 'Action: Correlation: click load more',
  CORRELATION_CLICK_POSITIVE: 'Action: Correlation: click on positive correlation',
  CORRELATION_CLICK_NEGATIVE: 'Action: Correlation: click on negative correlation',
  // Routing
  ROUTE_FUNNELS: 'Navigate to: /funnels',
  ROUTE_FUNNEL: 'Navigate to: /funnel',
  ROUTE_PORTFOLIOS: 'Navigate to: /portfolios',
  ROUTE_PORTFOLIO: 'Navigate to portfolio detail page',
  ROUTE_REPORTS: 'Navigate to: /reports',
  ROUTE_REPORT: 'Navigate to: /report',
  ROUTE_SETTINGS: 'Navigate to: /settings',
  ROUTE_SETTINGS_INTEGRATIONS: 'Navigate to: /settings?tab=integrations',
  ROUTE_CORRELATIONS: 'Navigate to: /correlations',
  ROUTE_ADHOOK: 'Navigate to: https://app.adhook.io/auth.html?t=66c453d826fed821fa8f6811&prompt=none',
  // Portfolio events
  PORTFOLIO_TAB_CHANGE: (tab: string) => `Action: Portfolio tab switch: ${tab}`,
  PORTFOLIO_SETTINGS_TAB_CHANGE: (tab: string) => `Action: Portfolio settings tab switch: ${tab}`,
  PORTFOLIO_CONTENT_TAB_CHANGE: (tab: string) => `Action: Portfolio content tab switch: ${tab}`,
  DATE_RANGE_CHANGE: 'Action: Date range: change',
  COMPARE_DATE_RANGE_CHANGE: 'Action: Compare date range: change',
  // Performance
  DOWNLOAD_XLSX: 'Action: Download performance table xlsx',
  SWITCH_CHART: 'Action: Switch performance chart',
  PORTFOLIO_FILTER: 'Action: Filter portfolio by provider/label',
  PERFORMANCE_FUNNEL_CUSTOMIZE: 'Action: Funnel: customize',
  // Optimize
  OPTIMIZE_SWITCH_STATUS: (status: string) => `Action: Optimize: switch status to ${status}`,
  APPLY_PROPOSAL_DIALOG: 'Action: Optimize: Clicked on Apply proposal',
  APPLY_PROPOSAL: 'Action: Optimize: Applied proposal',
  DISCARD_PROPOSAL_DIALOG: 'Action: Optimize: Clicked on Discard proposal',
  DISCARD_PROPOSAL: 'Action: Optimize: Discarded proposal',
  MAKE_VISIBLE: 'Action: Optimize: Made visible to all users',
  LAUNCH_OPTIMIZATION_DIALOG: 'Action: Optimize: Clicked on Launch optimization',
  LAUNCH_OPTIMIZATION: 'Action: Optimize: Launched an optimization',
  DOWNLOAD_OPTIMIZATION_REPORT: 'Action: Optimize: Download optimization xlsx',
  SKIP_CONTENT_FROM_PROPOSAL: 'Action: Optimize: Skipped content from proposal',
  INCLUDE_CONTENT_TO_PROPOSAL: 'Action: Optimize: Include content to proposal',
  // Validation
  VALIDATION_SWITCH_VIEW: (view: string) => `Action: Validation: switch view to ${view}`,
  VALIDATION_DOWNLOAD_XLSX: 'Action: Validation: Time-based download xlsx',
  VALIDATION_SHOW_DETAILS: 'Action: Validation: Show details',
  // Budget
  ADD_BUDGET_ITEM_DIALOG: 'Action: Budget: Clicked on Add budget item',
  ADD_BUDGET_ITEM: 'Action: Budget: Added budget item',
  REMOVE_BUDGET_ITEM: 'Action: Budget: Removed budget item',
  UPDATE_BUDGET_ITEM: 'Action: Budget: Updated budget item',
  END_BUDGET_ITEM: 'Action: Budget: Ended budget item',
  // Content
  CONTENT_CHANGE_IMPACT_GROUP: 'Action: Content: Change impact group',
  CONTENT_CHANGE_LABEL: 'Action: Content: Change label',
  CONTENT_DELETE: 'Action: Content: Delete',
  CONTENT_BUDGET_LIMIT_UPDATE: 'Action: Content: Update budget limit',
  CONTENT_EDIT_DIALOG: 'Action: Content: Edit content dialog',
  CONTENT_EDIT_DIALOG_V2: 'Action: Content: Open edit V2 content dialog',
  CONTENT_EDIT: 'Action: Content: Edit content',
  CONTENT_EDIT_V2: 'Action: Content: Edit V2 content',
  CONTENT_EXCLUDE_FROM_OPTIMIZATION: 'Action: Content: Exclude from optimization',
  CONTENT_INCLUDE_IN_OPTIMIZATION: 'Action: Content: Include in optimization',
  // Simulations
  SIMULATION_CREATE: 'Action: Simulation: Click Create simulation',
  SIMULATION_CREATE_DIALOG: 'Action: Simulation: Create',
  SIMULATION_UPDATE: 'Action: Simulation: Update',
  SIMULATION_DELETE: 'Action: Simulation: Delete',
  SIMULATION_ARCHIVE: 'Action: Simulation: Archive',
  SIMULATION_UNARCHIVE: 'Action: Simulation: Unarchive',
  SIMULATION_RUN: 'Action: Simulation: Run',
  SIMULATION_EXPLORE: 'Action: Simulation: Explore',
  SIMULATION_DOWNLOAD_XLSX: 'Action: Simulation: Download xlsx',
  SIMULATION_APPLY_SCENARIO: 'Action: Simulation: Apply scenario',
  SIMULATION_TAB_SWITCH: (tab: string) => `Action: Simulation: Switch tab to ${tab}`,
  // Portfolio funnel steps
  FUNNEL_STEP_EDIT: 'Action: Funnel step: Edit',
};

import { useDiscoverContentsStore } from '../store/discovered-contents';
import { useCountDiscoveredContents } from '../graphql/portfolioRules/queryCountDiscoveredContents';
import usePortfolioMetaStore from '../store/portfolio-meta';
import { PORTFOLIO_FEATURE_FLAGS } from '../constants/featureFlags';

const useTabNewUpdates = (portfolioId: number) => {
  const { setTabNewUpdates } = useDiscoverContentsStore();
  const { portfolioMeta } = usePortfolioMetaStore();

  const isSelfServiceEnabled = portfolioMeta?.featureFlags?.some(
    (ff) => ff.name === PORTFOLIO_FEATURE_FLAGS.SELF_SERVICE_PORTFOLIO && ff.status,
  );

  const { loading } = useCountDiscoveredContents({
    portfolioId,
    skip: !isSelfServiceEnabled,
    onCompleted: (data) => {
      const newDiscoveredContentsCount = data?.countDiscoveredContents.NEW;
      const unappliedDiscoveredContentsCount = data?.countDiscoveredContents.ACCEPTED_BUT_HAS_UNAPPLIED_RULES;

      if (isSelfServiceEnabled) {
        setTabNewUpdates('discoveredContents', newDiscoveredContentsCount > 0);
        setTabNewUpdates('unappliedRules', unappliedDiscoveredContentsCount > 0);
      } else {
        setTabNewUpdates('discoveredContents', false);
        setTabNewUpdates('unappliedRules', false);
      }
    },
  });

  return { loading };
};

export default useTabNewUpdates;

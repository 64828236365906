import dayjs from 'dayjs';

import {
  NexoyaFunnelStepV2,
  NexoyaOptimizationV2,
  NexoyaOptimizedContent,
  NexoyaOptimizedContentStatusType,
  NexoyaPortfolioV2,
  NexoyaTranslation,
} from 'types/types';

import { formatShortDate } from 'utils/formater';
import { getShareUrl } from 'utils/helpers';
import { capitalizeWords } from 'utils/string';
import translate from 'utils/translate';

import { translateBiddingStrategyType } from '../routes/portfolio/components/OptimizationProposal/utils';

const getArrayOfDatesFromDuration = (startDate: Date, endDate: Date): string[] => {
  const duration = dayjs(endDate).diff(dayjs(startDate), 'day');
  return Array.from({ length: duration + 1 }, (_, i) => dayjs(startDate).add(i, 'day').format('DD MMM YYYY'));
};

export function OptimizationDetailsReportController(
  portfolio: NexoyaPortfolioV2,
  optimization: NexoyaOptimizationV2,
  funnelSteps: NexoyaFunnelStepV2[],
  translations: NexoyaTranslation[],
  currency,
  numberFormat,
) {
  const optiDurationArray = getArrayOfDatesFromDuration(optimization.start, optimization.end).map((item) => ({
    timestamp: item,
  }));
  const optimizationContent = optimization?.performance?.contents.filter(
    (item) => item?.status?.type !== NexoyaOptimizedContentStatusType.Skipped,
  );
  const funnelStepsTotal = optimization.performance?.total?.funnelSteps.map((item, index) => ({
    title: funnelSteps[index]?.title,
    ...item,
  }));

  const totalBudgetChangePercent = optimization?.performance?.total?.budget?.changePercent;

  // There was a time when we had different daily budgets for each day
  // now we don't do that anymore, however we have kept the template as is for backwards compatibility for CSM
  const totalProposedBudget = optiDurationArray.map(() => optimization?.performance?.total?.budget?.proposed);

  // preparation of data for report header
  const reportHeader = [
    [
      {
        value: 'Portfolio',
        type: 'string',
      },
      {
        value: portfolio.title,
        type: 'string',
      },
    ],
    [
      {
        value: 'Duration',
        type: 'string',
      },
      {
        value: `${formatShortDate(
          portfolio?.start?.substring(0, 10),
          {
            year: 'numeric',
          },
          numberFormat,
        )} - ${formatShortDate(
          portfolio?.end?.substring(0, 10),
          {
            year: 'numeric',
          },
          numberFormat,
        )}`,
        type: 'string',
      },
    ],
    [
      {
        value: 'Optimization period: ',
        type: 'string',
      },
      {
        value: `${formatShortDate(
          optimization?.start?.substring(0, 10),
          {
            year: 'numeric',
          },
          numberFormat,
        )} - ${formatShortDate(
          optimization?.end?.substring(0, 10),
          {
            year: 'numeric',
          },
          numberFormat,
        )}`,
        type: 'string',
      },
    ],
    [
      {
        value: 'Currency',
        type: 'string',
      },
      {
        value: currency,
        type: 'string',
      },
    ],
    [
      {
        value: '',
        type: 'string',
      },
    ], // fake empty row
    [
      {
        value: 'Channel',
        type: 'string',
      },
      {
        value: 'Content',
        type: 'string',
      },
      {
        value: 'Parent Content',
        type: 'string',
      },
      {
        value: 'Type',
        type: 'string',
      },
      {
        value: 'Bidding strategy',
        type: 'string',
      },
      {
        value: 'Initial target',
        type: 'string',
      },
      {
        value: 'Proposed target',
        type: 'string',
      },
      {
        value: 'Proposed target change',
        type: 'string',
      },
      {
        value: 'Previous lifetime budget',
        type: 'string',
      },
      {
        value: 'Proposed lifetime budget',
        type: 'string',
      },
      {
        value: 'Lifetime budget change',
        type: 'string',
      },
      {
        value: 'Previous daily spend',
        type: 'string',
      },
      {
        value: 'Proposed daily budget',
        type: 'string',
      },
      {
        value: 'Budget delta',
        type: 'string',
      },
      {
        value: 'Change in compare to last days budget',
        type: 'string',
      },
      ...funnelStepsTotal.map((item) => ({
        value: `Cost-per ${item.title}`.toUpperCase(),
        type: 'string',
      })),
      ...funnelStepsTotal.map((item) => ({
        value: capitalizeWords(`Total ${item.title}`),
        type: 'string',
      })),
      ...optiDurationArray.map((item) => ({
        value: item.timestamp,
        type: 'string',
      })),
    ],
  ];
  // preparation of data for report content
  const reportContent = (optimizationContent || [])
    .filter((item) => item?.status?.type !== NexoyaOptimizedContentStatusType.Skipped)
    .map((item: NexoyaOptimizedContent) => {
      const providerName = item?.content.provider.name;
      // Previous === spent
      const previousDailyBudget = item?.budget?.spent;
      const proposedDailyBudget = item?.budget?.proposed;
      const dailyBudgetPercentageChange = item?.budget?.changePercent;

      const funnelSteps = item?.funnelSteps || [];
      // There was a time when we had different daily budgets for each day
      // now we don't do that anymore, however we have kept the template as is for backwards compatibility for CSM
      const optiDurationArrayWithProposedBudget = optiDurationArray.map((date) => ({
        value: item?.budget?.proposed,
        timestamp: date,
      }));

      const biddingStrategyType = item?.budgetProposalData?.proposedBiddingStrategy?.type;

      const previousLifetimeBudget = item?.budgetProposalData?.lifetimeBudget?.lifetimeBudgetSegments?.reduce(
        (acc, currItem) => acc + currItem.initialBudget,
        0,
      );
      const proposedLifetimeBudget = item?.budgetProposalData?.lifetimeBudget?.lifetimeBudgetSegments?.reduce(
        (acc, currItem) => acc + currItem.proposedBudget,
        0,
      );
      const lifetimeBudgetChangePercent =
        ((proposedLifetimeBudget - previousLifetimeBudget) / previousLifetimeBudget) * 100;

      const contentData = [
        {
          value: translate(translations, providerName),
          type: 'string',
        },
        {
          value: item?.content?.title || '',
          type: 'string',
        },
        {
          value: item?.content?.parent_collection?.title,
          type: 'string',
        },
        {
          value: item?.content?.collectionType?.name || '',
          type: 'string',
        },
        {
          value: translateBiddingStrategyType(biddingStrategyType) || '',
          type: 'string',
          wrap: true,
        },
        {
          value: item.budgetProposalData?.initialBiddingStrategy?.value || '',
          type: Number,
        },
        {
          value: item.budgetProposalData?.proposedBiddingStrategy?.value || '',
          type: Number,
        },
        {
          value: item.budgetProposalData?.biddingStrategyChangePercent
            ? Intl.NumberFormat(numberFormat).format(item.budgetProposalData?.biddingStrategyChangePercent) + '%'
            : '',
          type: Number,
        },

        {
          value: previousLifetimeBudget ? previousLifetimeBudget : '',
          type: Number,
        },
        {
          value: proposedLifetimeBudget ? proposedLifetimeBudget : '',
          type: Number,
        },
        {
          value: lifetimeBudgetChangePercent ? parseFloat(lifetimeBudgetChangePercent?.toFixed(2)) + '%' : '',
          type: Number,
        },
        {
          value: previousDailyBudget || '',
          type: Number,
        },
        {
          value: proposedDailyBudget || '',
          type: Number,
        },
        {
          value: parseFloat(Math.abs(previousDailyBudget - proposedDailyBudget).toFixed(2)) || '',
          type: Number,
        },
        {
          value: dailyBudgetPercentageChange ? `${dailyBudgetPercentageChange}%` : '',
          type: 'string',
        },
        ...funnelSteps.map((item) => ({
          value: item?.costPer?.predicted || '',
          type: Number,
        })),
        ...funnelSteps.map((item) => ({
          value: item?.metric?.predicted || '',
          type: Number,
        })),
        ...optiDurationArrayWithProposedBudget.map((da) => ({
          value: da?.value || '',
          type: Number,
        })),
      ];
      return [...contentData];
    });
  // preparation of data for report footer (sums)
  const reportFooter = [
    [
      {
        type: 'string',
        value: 'Total ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: Number,
        value: ' ',
      },
      {
        type: Number,
        value: ' ',
      },
      {
        type: 'string',
        value: ' ',
      },
      {
        type: 'string',
        value: Intl.NumberFormat(numberFormat, {
          style: 'currency',
          currency: currency,
        }).format(optimization?.performance?.total?.budget?.spent),
      },
      {
        type: 'string',
        value: Intl.NumberFormat(numberFormat, {
          style: 'currency',
          currency: currency,
        }).format(optimization?.performance?.total?.budget?.proposed),
      },
      {
        type: 'string',
        value: Intl.NumberFormat(numberFormat, {
          style: 'currency',
          currency: currency,
        }).format(
          Math.abs(
            optimization?.performance?.total?.budget?.spent - optimization?.performance?.total?.budget?.proposed,
          ),
        ),
      },
      {
        type: 'string',
        value: `${Intl.NumberFormat(numberFormat).format(totalBudgetChangePercent)}%`,
      },
      ...funnelStepsTotal.map((item) => ({
        type: 'string',
        value: Intl.NumberFormat(numberFormat, {
          style: 'currency',
          currency: currency,
        }).format(item?.costPer?.predicted),
      })),
      ...funnelStepsTotal.map((item) => ({
        type: Number,
        value: Intl.NumberFormat(numberFormat).format(item?.metric?.predicted),
      })),
      ...totalProposedBudget.map((item) => ({
        type: 'string',
        value: Intl.NumberFormat(numberFormat, {
          style: 'currency',
          currency: currency,
        }).format(item),
      })),
    ],
    [
      {
        type: 'string',
        value: `Relevant links:`,
      },
    ],
    [
      {
        type: 'string',
        value: `${getShareUrl(portfolio.portfolioId, 'portfolio')}`,
      },
    ],
    [
      {
        type: 'string',
        value: 'https://www.nexoya.com/help/?embedded=true',
      },
    ],
  ];
  return {
    reportHeader,
    reportContent,
    reportFooter,
  };
}

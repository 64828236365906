import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components-ui/AlertDialog';
import React, { useEffect, useState } from 'react';
import { LabelLight } from '../../../../components/InputLabel/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import ButtonAsync from '../../../../components/ButtonAsync';
import { NexoyaPortfolioContentDetail } from '../../../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  item: NexoyaPortfolioContentDetail;
  onConfirm: (rules: { contentRuleId: number; impactGroupRuleId: number }) => Promise<void>;
  loading: boolean;
}

export const ChangeRulesDialog = ({ isOpen, onClose, item, onConfirm, loading }: Props) => {
  const initialContentRule = item.discoveredContent?.contentRules
    ?.find((rule) => rule.isApplied)
    ?.contentRule?.contentRuleId?.toString();
  const initialImpactRule = item.discoveredContent?.impactGroupRules
    ?.find((rule) => rule.isApplied)
    ?.impactGroupRule?.impactGroupRuleId?.toString();

  const [selectedContentRule, setSelectedContentRule] = useState(initialContentRule ?? '');
  const [selectedImpactRule, setSelectedImpactRule] = useState(initialImpactRule ?? '');

  const hasContentRuleChange = selectedContentRule && selectedContentRule !== initialContentRule;
  const hasImpactRuleChange = selectedImpactRule && selectedImpactRule !== initialImpactRule;
  const hasChanges = hasContentRuleChange || hasImpactRuleChange;

  const handleConfirm = async () => {
    try {
      await onConfirm({
        contentRuleId: parseInt(selectedContentRule),
        impactGroupRuleId: parseInt(selectedImpactRule),
      });
    } catch (error) {
      console.error('Error applying rules:', error);
    }
  };

  useEffect(() => {
    setSelectedContentRule(initialContentRule ?? '');
    setSelectedImpactRule(initialImpactRule ?? '');
  }, [initialContentRule, initialImpactRule]);

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change content rules</AlertDialogTitle>
          <AlertDialogDescription>
            <span className="font-normal text-neutral-400">Select different rules to assign to this content.</span>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="flex flex-col gap-4 py-4">
          <div className="flex flex-col gap-2">
            <LabelLight className="!mb-0 px-0 font-semibold">Content rule metrics</LabelLight>
            <Select
              disabled={!item.discoveredContent?.contentRules?.length || loading}
              value={selectedContentRule || undefined}
              onValueChange={setSelectedContentRule}
            >
              <SelectTrigger className="w-full border-neutral-100 bg-transparent p-2">
                <SelectValue placeholder="Select content rule metrics" />
              </SelectTrigger>
              <SelectContent>
                {item.discoveredContent?.contentRules?.map((cr) => (
                  <SelectItem key={cr.contentRule.contentRuleId} value={cr.contentRule.contentRuleId.toString()}>
                    {cr.contentRule.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-2">
            <LabelLight className="!mb-0 px-0 font-semibold">Impact group rule</LabelLight>
            <Select
              disabled={!item.discoveredContent?.impactGroupRules?.length || loading}
              value={selectedImpactRule || undefined}
              onValueChange={setSelectedImpactRule}
            >
              <SelectTrigger className="w-full border-neutral-100 bg-transparent p-2">
                <SelectValue placeholder="Select impact group rule" />
              </SelectTrigger>
              <SelectContent>
                {item.discoveredContent?.impactGroupRules?.map((igr) => (
                  <SelectItem
                    key={igr.impactGroupRule.impactGroupRuleId}
                    value={igr.impactGroupRule.impactGroupRuleId.toString()}
                  >
                    {igr.impactGroupRule.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync disabled={loading} onClick={onClose} variant="contained" color="secondary" size="small">
              Cancel
            </ButtonAsync>
          </AlertDialogAction>
          <AlertDialogAction>
            <ButtonAsync
              disabled={loading || !hasChanges}
              onClick={handleConfirm}
              loading={loading}
              variant="contained"
              color="primary"
              size="small"
            >
              Apply rules to content
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

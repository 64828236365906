import * as Styles from '../../styles/ContentTableRow';
import SvgChevronDown from '../../../../components/icons/ChevronDown';
import React from 'react';
import { NexoyaFunnelStepV2 } from '../../../../types';
import { capitalize } from 'lodash';
import { withSortSkipped } from '../OptimizationProposal/withSortSkipped';
import { sortTypes } from '../../../../components/Table/sortTypes';
import styled from 'styled-components';
import { nexyColors } from '../../../../theme';
import { BigHeaderCell } from 'routes/portfolio/styles/OptimizationProposal';

export const HeaderCell = styled.div`
  color: ${nexyColors.neutral400};

  /* Headings/H6 */
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 15.4px */
  letter-spacing: 0.44px;
  text-transform: uppercase;
`;

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  hasBidStrategy: boolean;
  hasBudgetLimits: boolean;
  hasLabels: boolean;
}

export const getColumns = ({ funnelSteps, hasLabels, hasBidStrategy, hasBudgetLimits }: Props) =>
  [
    {
      Header: '',
      accessor: 'editRow',
      id: 'editRow',
      width: '48',
      disableSortBy: true,
      disableHiding: true,
      isHiddenInManager: true,
    },
    {
      width: 50,
      disableSortBy: true,
      disableHiding: true,
      isHiddenInManager: true,
      id: 'expander',
      Cell: ({ row }) =>
        row.canExpand ? (
          <Styles.ChevronWrap
            expanded={row.isExpanded}
            className="mx-auto h-full w-fit items-center !justify-center"
            {...row.getToggleRowExpandedProps()}
          >
            <SvgChevronDown />
          </Styles.ChevronWrap>
        ) : null,
    },
    {
      Header: <HeaderCell className="!py-4">Content</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>Content</BigHeaderCell>,
      id: 'content',
      accessor: 'content',
      className: 'border-right',
      minWidth: 300,
      sortType: withSortSkipped(sortTypes.jsxKey),
      disableHiding: true,
      enableColumnResize: true,
    },
    {
      Header: <HeaderCell>Optimization</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>Optimization</BigHeaderCell>,
      width: 200,
      accessor: 'optimization',
      className: 'border-right',
      enableColumnResize: true,
      sortType: withSortSkipped(sortTypes.jsxKey),
    },
    {
      Header: <HeaderCell>Content level</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>Content level</BigHeaderCell>,
      width: 200,
      accessor: 'contentLevel',
      className: 'border-right',
      enableColumnResize: true,
      sortType: withSortSkipped(sortTypes.jsxKey),
    },
    {
      Header: <HeaderCell>Content mode</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>Content mode</BigHeaderCell>,
      width: 200,
      accessor: 'contentMode',
      className: 'border-right',
      enableColumnResize: true,
      sortType: withSortSkipped(sortTypes.jsxKey),
    },
    hasLabels
      ? {
          Header: <HeaderCell>Label</HeaderCell>,
          tableManagerHeader: <BigHeaderCell>Label</BigHeaderCell>,
          enableColumnResize: true,
          className: 'border-right',
          accessor: 'label',
          sortType: withSortSkipped(sortTypes.jsxKey),
        }
      : null,
    {
      Header: <HeaderCell>Impact group</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>Impact group</BigHeaderCell>,
      accessor: 'impactGroup',
      enableColumnResize: true,
      className: 'border-right',
      minWidth: 250,
      sortType: withSortSkipped(sortTypes.jsxKey),
    },
    hasBudgetLimits
      ? {
          Header: <HeaderCell>Budget limit</HeaderCell>,
          tableManagerHeader: <BigHeaderCell>Budget limit</BigHeaderCell>,
          enableColumnResize: true,
          className: 'border-right',
          disableSortBy: true,
          accessor: 'budgetLimit',
          minWidth: 250,
        }
      : null,
    hasBidStrategy
      ? {
          Header: <HeaderCell>Bidding strategy</HeaderCell>,
          tableManagerHeader: <BigHeaderCell>Bidding strategy</BigHeaderCell>,
          enableColumnResize: true,
          accessor: 'bidStrategy',
          className: 'border-right',
          disableSortBy: true,
          minWidth: 250,
        }
      : null,
    ...funnelSteps.map((funnelStep) => ({
      className: 'border-right',
      Header: <HeaderCell>{capitalize(funnelStep.title)}</HeaderCell>,
      tableManagerHeader: <BigHeaderCell>{capitalize(funnelStep.title)}</BigHeaderCell>,
      accessor: `funnel_${funnelStep.funnelStepId}`,
      disableSortBy: true,
      enableColumnResize: true,
      minWidth: 250,
      sortType: withSortSkipped(sortTypes.jsxKeyAsNumber),
    })),
  ].filter(Boolean);

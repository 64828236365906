import { gql, useLazyQuery } from '@apollo/client';
import { toast } from 'sonner';
import { NexoyaContentRuleEditInput, NexoyaContentRuleUpdatePreviewMutationResponse } from '../../types';

const CONTENT_RULE_UPDATE_PREVIEW_MUTATION = gql`
  query ContentRuleUpdatePreview(
    $contentRuleEdit: ContentRuleEditInput!
    $contentRuleId: Float!
    $portfolioId: Float!
    $teamId: Float!
  ) {
    contentRuleUpdatePreview(
      contentRuleEdit: $contentRuleEdit
      contentRuleId: $contentRuleId
      portfolioId: $portfolioId
      teamId: $teamId
    ) {
      newMatchingDiscoveredContents {
        discoveredContentId
        impactGroupRules {
          isApplied
          impactGroupRule {
            impactGroupRuleId
            name
          }
        }
        contentRules {
          isApplied
          contentRule {
            contentRuleId
            name
            filters {
              providerId
              adAccountIds
              contentFilters {
                fieldName
                value {
                  number
                }
              }
            }
            funnelStepMappings {
              funnelStepId
              mapping {
                conversions {
                  accountConversionIds
                  conversionName
                  metricId
                }
                metricId
                utmParams {
                  values
                  type
                }
              }
            }
          }
        }
        content {
          portfolioContentId(portfolioId: $portfolioId)
          contentId
          title
          provider {
            provider_id
            name
          }
          contentType {
            name
          }
          parent {
            title
          }
        }
      }
      noLongerMatchingDiscoveredContents {
        discoveredContentId
        impactGroupRules {
          isApplied
          impactGroupRule {
            impactGroupRuleId
            name
          }
        }
        contentRules {
          isApplied
          contentRule {
            filters {
              contentFilters {
                fieldName
                value {
                  number
                }
              }
            }
            funnelStepMappings {
              funnelStepId
              mapping {
                conversions {
                  accountConversionIds
                  conversionName
                  metricId
                }
                metricId
                utmParams {
                  values
                  type
                }
              }
            }
            contentRuleId
            name
          }
        }
        content {
          portfolioContentId(portfolioId: $portfolioId)
          contentId
          title
          provider {
            provider_id
            name
          }
          contentType {
            name
          }
          parent {
            title
          }
        }
      }
    }
  }
`;

type ContentRuleUpdatePreviewProps = {
  teamId: number;
  portfolioId: number;
  contentRuleId: number;
  contentRuleEdit: NexoyaContentRuleEditInput;
};

export function useContentRuleUpdatePreviewQuery() {
  return useLazyQuery<
    { contentRuleUpdatePreview: NexoyaContentRuleUpdatePreviewMutationResponse },
    ContentRuleUpdatePreviewProps
  >(CONTENT_RULE_UPDATE_PREVIEW_MUTATION, {
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data?.contentRuleUpdatePreview) {
        const { newMatchingDiscoveredContents, noLongerMatchingDiscoveredContents } = data.contentRuleUpdatePreview;

        const addedCount = newMatchingDiscoveredContents.length;
        const removedCount = noLongerMatchingDiscoveredContents.length;

        toast.success('Content rule preview', {
          description: `${addedCount} new matching contents, ${removedCount} no longer matching`,
        });
      } else {
        toast.error('Failed to preview content rule update');
      }
    },
  });
}

type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgNoFillPencil = (props: Props) => (
  <svg
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path d="M16.1773 4.7986L15.2005 3.82178C14.875 3.49626 14.4484 3.3335 14.0219 3.3335C13.5953 3.3335 13.1687 3.49626 12.8435 3.82152L10.9036 5.76136L9.99869 6.66631L3.66669 12.9981L3.33596 15.9726C3.2943 16.347 3.58935 16.6668 3.95575 16.6668C3.97893 16.6668 4.00237 16.6655 4.0258 16.6629L6.99819 16.3345L13.3325 10.0002L14.2372 9.09548L16.1771 7.15563C16.8281 6.50459 16.8281 5.44938 16.1773 4.7986ZM6.42439 15.1405L4.6645 15.3348L4.86059 13.572L10.8824 7.55016L11.6902 6.74234L13.2564 8.30849L12.4486 9.1163L6.42439 15.1405ZM14.14 7.42465L15.2931 6.27152C15.4556 6.10928 15.4556 5.84496 15.2931 5.6822L14.3163 4.70538C14.2103 4.59913 14.0861 4.58324 14.0218 4.58324C13.9574 4.58324 13.8335 4.59913 13.7272 4.70538L12.5741 5.8585L14.14 7.42465Z" />
  </svg>
);

export default SvgNoFillPencil;

type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
  className?: string;
};

const Link2 = (props: Props) => (
  <svg
    aria-hidden={true}
    width="1em"
    height="1em"
    display="block"
    fill="currentColor"
    focusable={false}
    role="presentation"
    viewBox="0 0 20 21"
    {...props}
  >
    <circle cx="10" cy="10.5" r="10" fill="#FCF1BA" />
    <path
      d="M9.47159 12.6418C9.346 12.4233 9.28051 12.1753 9.28176 11.9232C9.28301 11.6712 9.35097 11.4239 9.47873 11.2066C10.0316 11.1616 10.5456 10.9039 10.9123 10.4876C11.279 10.0714 11.47 9.52915 11.445 8.97497C11.42 8.4208 11.1811 7.89792 10.7784 7.51638C10.3757 7.13484 9.84066 6.92439 9.28594 6.92933H6.42969C5.86154 6.92933 5.31667 7.15503 4.91493 7.55677C4.51319 7.9585 4.2875 8.50338 4.2875 9.07152C4.2875 9.63966 4.51319 10.1845 4.91493 10.5863C5.31667 10.988 5.86154 11.2137 6.42969 11.2137H6.48681C6.40772 11.6865 6.40772 12.1691 6.48681 12.6418H6.42969C5.48278 12.6418 4.57466 12.2657 3.9051 11.5961C3.23553 10.9265 2.85938 10.0184 2.85938 9.07152C2.85938 8.12461 3.23553 7.21649 3.9051 6.54693C4.57466 5.87736 5.48278 5.50121 6.42969 5.50121H9.28594C10.2328 5.47659 11.1507 5.82913 11.8377 6.48129C12.5247 7.13344 12.9245 8.03179 12.9491 8.97869C12.9737 9.9256 12.6212 10.8435 11.969 11.5305C11.3168 12.2174 10.4185 12.6172 9.47159 12.6418ZM10.5284 8.35746C10.654 8.57604 10.7195 8.82397 10.7182 9.07605C10.717 9.32814 10.649 9.57541 10.5213 9.79272C9.96835 9.83771 9.45445 10.0954 9.08771 10.5117C8.72097 10.9279 8.52999 11.4701 8.55497 12.0243C8.57995 12.5785 8.81894 13.1014 9.22164 13.4829C9.62434 13.8645 10.1593 14.0749 10.7141 14.07H13.5703C14.1385 14.07 14.6833 13.8443 15.0851 13.4425C15.4868 13.0408 15.7125 12.4959 15.7125 11.9278C15.7125 11.3596 15.4868 10.8148 15.0851 10.413C14.6833 10.0113 14.1385 9.78558 13.5703 9.78558H13.5132C13.5923 9.31283 13.5923 8.83021 13.5132 8.35746H13.5703C14.5172 8.35746 15.4253 8.73362 16.0949 9.40318C16.7645 10.0727 17.1406 10.9809 17.1406 11.9278C17.1406 12.8747 16.7645 13.7828 16.0949 14.4524C15.4253 15.1219 14.5172 15.4981 13.5703 15.4981H10.7141C10.2452 15.5103 9.77853 15.43 9.3407 15.2618C8.90286 15.0937 8.50244 14.8409 8.16228 14.518C7.47531 13.8658 7.07554 12.9675 7.05092 12.0206C7.0263 11.0737 7.37885 10.1558 8.031 9.46882C8.68316 8.78185 9.5815 8.38208 10.5284 8.35746Z"
      fill="#F5CF0F"
    />
  </svg>
);

export default Link2;

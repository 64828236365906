import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { NexoyaContentRuleFiltersInput, NexoyaCreateContentRuleAndDiscoverContentsMutationResponse } from '../../types';
import { CONTENT_RULES_QUERY } from './queryContentRules';
import { useTeam } from '../../context/TeamProvider';
import { PORTFOLIO_QUERY } from '../portfolio/queryPortfolio';
import { StringParam, useQueryParams } from 'use-query-params';
import { UTC_TIMEZONE } from '../../utils/dates';

const CREATE_CONTENT_GROUP_MUTATION = gql`
  mutation CreateContentRule(
    $filters: ContentRuleFiltersInput!
    $name: String!
    $portfolioId: Float!
    $teamId: Float!
    $contentIdsToAccept: [Float!]!
  ) {
    createContentRuleAndDiscoverContents(
      filters: $filters
      name: $name
      portfolioId: $portfolioId
      teamId: $teamId
      contentIdsToAccept: $contentIdsToAccept
    ) {
      contentRule {
        contentRuleId
        name
        filters {
          adAccountIds
          providerId
        }
        funnelStepMappings {
          funnelStepId
          mapping {
            conversions {
              accountConversionIds
              conversionName
              metricId
            }
            type
            metricId
            utmParams {
              type
              values
            }
          }
        }
      }
      discoveredContents {
        discoveredContentId
        content {
          title
          contentId
        }
        contentRules {
          isApplied
          contentRule {
            contentRuleId
            name
          }
        }
      }
    }
  }
`;

type CreateContentRuleProps = {
  name: string;
  teamId: number;
  s;
  portfolioId: number;
  providerId: number;
  adAccountId?: number;
  onCompleted?: (data: NexoyaCreateContentRuleAndDiscoverContentsMutationResponse) => void;
  filters: NexoyaContentRuleFiltersInput;
};

export function useCreateContentRuleMutation({ portfolioId, onCompleted }) {
  const { teamId } = useTeam();
  const [qp] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
  });

  return useMutation<
    { createContentRuleAndDiscoverContents: NexoyaCreateContentRuleAndDiscoverContentsMutationResponse },
    CreateContentRuleProps
  >(CREATE_CONTENT_GROUP_MUTATION, {
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      toast.success(
        `Content rule ${data?.createContentRuleAndDiscoverContents?.contentRule?.name} created successfully`,
        {
          description: `The content rule has ${data?.createContentRuleAndDiscoverContents?.discoveredContents?.length} matching contents`,
        },
      );
      onCompleted(data);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: CONTENT_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId,
          withBudget: false,
          dateFrom: qp.dateFrom + UTC_TIMEZONE,
          dateTo: qp.dateTo + UTC_TIMEZONE,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}

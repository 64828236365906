import { NexoyaContentRuleFilters, NexoyaContentV2, NexoyaImpactGroupRuleFilters } from '../../../types';

export const CONTENT_TYPE_SUB_ACCOUNT_NUMBER = 2;

export const extractProviderMapFromFilters = (
  filter: NexoyaContentRuleFilters | NexoyaImpactGroupRuleFilters,
  subAccounts: NexoyaContentV2[],
): Record<number, number[]> => {
  const providerMap: Record<number, number[]> = {};

  const isContentRule = (filter: any): filter is NexoyaContentRuleFilters => filter.__typename === 'ContentRuleFilters';

  const providerIds = isContentRule(filter) ? [filter.providerId] : filter.providers.map((p) => p.providerId);
  const parentContentIds = isContentRule(filter)
    ? filter.adAccountIds
    : filter.providers.flatMap((p) => p.adAccountIds);

  // Find matching subAccounts and map their providers to content IDs
  for (const providerId of providerIds) {
    const matchingSubAccounts = subAccounts.filter(
      (account) => account.provider?.provider_id === providerId && parentContentIds.includes(account.contentId),
    );

    matchingSubAccounts.forEach((account) => {
      if (!providerMap[providerId]) {
        providerMap[providerId] = [];
      }
      providerMap[providerId].push(account.contentId);
    });
  }

  return providerMap;
};

export const FEATURE_FLAGS = {
  DASHBOARD_NEW_DISABLED: 'portfolio_dashboard_disabled',
  PERFORMANCE_NEW_DISABLED: 'performance_new_disabled',
  NEXOYA_DEMO: 'nexoya_demo',
  PORTFOLIO_TARGET_TYPE: 'portfolio_target_type',
  SIMULATIONS: 'simulations',
  GOOGLE_ADS_CAMPAIGN_MANAGEMENT: 'google_ads_campaign_management',
};

export const PORTFOLIO_FEATURE_FLAGS = {
  BUDGET_V1: 'budget_v1',
  WHAT_IF_VALIDATION: 'what_if_validation',
  CONTENT_EDIT_V2: 'content_edit_v2',
  SELF_SERVICE_PORTFOLIO: 'self_service_portfolio',
  OPTIMIZATION_VISIBLE_TO_ALL_USERS: 'optimizationsOnlyVisibleToSupportUsers',
  SIMULATION_DATA_DRIVEN_BUDGET_PACING: 'simulation_data_driven_budget_pacing',
  SIMULATION_PREVIEW_SUPPORT: 'simulation_preview_support',
};

export const SUPPORT_ONLY_PORTFOLIO_FEATURE_FLAGS = {
  SELF_SERVICE_PORTFOLIO: 'self_service_portfolio',
  WHAT_IF_VALIDATION: 'what_if_validation',
  OPTIMIZATION_VISIBLE_TO_ALL_USERS: 'optimizationsOnlyVisibleToSupportUsers',
  SIMULATION_DATA_DRIVEN_BUDGET_PACING: 'simulation_data_driven_budget_pacing',
  SIMULATION_PREVIEW_SUPPORT: 'simulation_preview_support',
};

import React, { useCallback, useEffect, useState } from 'react';
import { Match, withRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import styled from 'styled-components';

import {
  NexoyaBudgetDeltaHandlingPolicy,
  NexoyaPortfolioDashboardUrl,
  NexoyaPortfolioType,
  NexoyaPortfolioV2,
  NexoyaTargetBiddingApplyMode,
} from 'types';

import { useUpdatePortfolioMutation } from '../../../graphql/portfolio/mutationUpdatePortfolio';
import { useUserQuery } from '../../../graphql/user/queryUser';

import useAllowSubmit from '../../../hooks/useAllowSubmit';
import { format } from '../../../utils/dates';
import { BUDGET_DELTA_OPTIONS, budgetProposalTargetBiddingApplyTypes, riskTypes } from '../../../utils/portfolioEdit';

import Button from '../../../components/Button';
import ButtonAdornment from '../../../components/ButtonAdornment';
import Checkbox from '../../../components/Checkbox';
import { DateSelector } from '../../../components/DateSelector';
import { useDialogState } from '../../../components/Dialog';
import { useDropdownMenu } from '../../../components/DropdownMenu';
import ErrorMessage from '../../../components/ErrorMessage';
import Fieldset from '../../../components/Form/Fieldset/Fieldset';
import FormGroup from '../../../components/Form/FormGroup';
import FormControlLabel from '../../../components/FormControlLabel';
import MenuItem from '../../../components/MenuItem';
import MenuList from '../../../components/MenuList';
import Panel from '../../../components/Panel';
import Radio from '../../../components/Radio';
import RadioGroup from '../../../components/RadioGroup';
import TextField from '../../../components/TextField';
import Typography from '../../../components/Typography';
import SvgCaretDown from '../../../components/icons/CaretDown';
import SvgPlusRegular from '../../../components/icons/PlusRegular';
import { BudgetOptimizationItem, mapRiskIcon } from '../../portfolios/components/PortfolioBudget';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import { Button as ShadcnButton } from '../../../components-ui/Button';
import SvgEllipsisV from '../../../components/icons/EllipsisV';
import { useUnsavedChanges } from '../../../context/UnsavedChangesProvider';
import { isEqual, sortBy } from 'lodash';
import { nexyColors } from '../../../theme';
import ButtonAsync from '../../../components/ButtonAsync';
import { budgetOptimizationType } from '../../../configs/portfolio';
import { LabelsEditTable } from '../components/Labels/LabelsEditTable';
import { ConfirmationDialog } from '../components/PortfolioEditFunnel/ConfirmationDialog';
import { useLocalLabels } from '../../../hooks/useLocalLabels';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  portfolio: NexoyaPortfolioV2;
  match: Match;
};
type FormValues = {
  title: string;
  startDate: Date;
  endDate: Date;
  optimizationType: string;
  optimizationRiskLevel: number;
  portfolioDashboardUrls: NexoyaPortfolioDashboardUrl[];
  budgetDeltaHandlingPolicy: NexoyaBudgetDeltaHandlingPolicy;
  budgetProposalTargetBiddingApplyMode: NexoyaTargetBiddingApplyMode;
  skipTrainingDays?: number;
};
const WrapStyled = styled.div`
  width: 100%;

  .riskLevel {
    text-transform: uppercase;
  }
`;

export const BudgetDeltasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BudgetDelaOptionsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 12px;
  flex-wrap: wrap;
`;

export const OptionCard = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  padding: 48px 14px 24px 14px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;

  flex: 1 1 150px;

  &:last-child {
    padding-top: 24px;
  }

  &:hover {
    background: ${({ selected }) => (selected ? nexyColors.seasalt : nexyColors.paleWhite)};
  }

  background: ${({ selected }) => (selected ? nexyColors.seasalt : 'white')};
  box-shadow: ${({ selected }) => (selected ? '' : '0px 2px 3px -1px rgba(42, 43, 46, 0.1)')};
`;

const StyledCustomDivider = styled.div`
  text-align: center;
  position: relative;
  margin: 20px 0;

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #eaeaea;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  font-size: 1em;
  color: #000; /* or any color you prefer */
`;

function PortfolioGeneralSettings({ match, portfolio }: Props) {
  const portfolioId = parseInt(match.params.portfolioID, 10);

  if (!portfolio) {
    return 'Loading...';
  }

  const { data: userData } = useUserQuery({ fetchPolicy: 'network-only' });
  const {
    open: isSkipTrainingMenuOpen,
    closeMenu: closeSkipTrainingMenu,
    toggleMenu: toggleSkipTrainingMenu,
    anchorEl: skipTrainingAnchor,
  } = useDropdownMenu();
  const { isOpen: isDialogOpen, toggleDialog } = useDialogState({
    initialState: false,
  });

  const {
    title,
    start,
    end,
    optimizationType,
    optimizationRiskLevel,
    budgetDeltaHandlingPolicy,
    portfolioDashboardUrls,
    budgetProposalTargetBiddingApplyMode,
    skipTrainingDays,
  } = portfolio;

  const [metabaseLinks, setMetabaseLinks] = useState<NexoyaPortfolioDashboardUrl[]>(
    portfolioDashboardUrls?.length ? portfolioDashboardUrls : [{ name: '', url: '' }],
  );
  const { isOpen: isApplyOpen, openDialog: openApplyDialog, closeDialog: closeApplyDialog } = useDialogState();
  const { isOpen: isDiscardOpen, openDialog: openDiscardDialog, closeDialog: closeDiscardDialog } = useDialogState();
  const { setHasUnsavedChanges } = useUnsavedChanges();

  const {
    labels: labelsState,
    setLabels,
    resetLabels,
    handleEdit: handleEditLabels,
    handleDelete: handleDeleteLabels,
    commitChanges: commitLabelsChanges,
    hasPendingChanges: hasPendingLabelsChanges,
  } = useLocalLabels({ portfolioMeta: portfolio });

  const initialState: FormValues = {
    title,
    startDate: new Date((start || '').substring(0, 10)),
    endDate: new Date((end || '').substring(0, 10)),
    optimizationType: optimizationType || '',
    optimizationRiskLevel: optimizationRiskLevel || 0,
    portfolioDashboardUrls: portfolioDashboardUrls?.length
      ? portfolioDashboardUrls.filter((pdu) => pdu.url && pdu.name).map(({ name, url }) => ({ name, url }))
      : [],
    budgetProposalTargetBiddingApplyMode,
    budgetDeltaHandlingPolicy,
    skipTrainingDays,
  };
  const [form, setForm] = React.useState<FormValues>(initialState);

  useEffect(() => {
    setForm(initialState);
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(
      !isEqual(initialState, form) || !isEqual(sortBy(labelsState, 'labelId'), sortBy(portfolio.labels, 'labelId')),
    );
  }, [initialState, form]);

  const handleChange = useCallback(
    (ev: { target: { name: keyof FormValues; value: unknown } }) => {
      const { name, value } = ev.target;
      setForm((s) => ({ ...s, [name]: value }));
    },
    [setForm],
  );

  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  function handleDateChange({ from, to }) {
    setForm({
      ...form,
      startDate: new Date(format(from, 'utcMidday', true)),
      endDate: new Date(format(to, 'utcMidday', true)),
    });
  }

  useEffect(() => {
    if (metabaseLinks.filter((mb) => mb.name && mb.url)) {
      setForm((prevState) => ({
        ...prevState,
        portfolioDashboardUrls: metabaseLinks.filter((mb) => mb.name && mb.url).map(({ name, url }) => ({ name, url })),
      }));
    }
  }, [metabaseLinks]);

  const allowSubmit =
    useAllowSubmit({
      initialValues: initialState,
      values: form,
    }).allowSubmit || hasPendingLabelsChanges;

  const [updatePortfolioMeta, { loading, error }] = useUpdatePortfolioMutation({
    portfolioId: portfolioId,
    ...form,
  });

  async function handleSubmit() {
    try {
      const res = await updatePortfolioMeta();
      await commitLabelsChanges();

      if (res?.data?.updatePortfolio) {
        if (isDialogOpen) {
          toggleDialog();
        } // quick fix, always close dialog (even
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  const getWarningMessageIfChangingDates = () => {
    if (
      !dayjs(initialState?.startDate).isSame(dayjs(form.startDate), 'day') ||
      !dayjs(initialState?.endDate).isSame(dayjs(form.endDate), 'day')
    ) {
      return 'Changing dates will affect the budget, so you will have to set it up again.';
    } else return null;
  };

  const portfolioRiskType = portfolio.type === NexoyaPortfolioType.Budget ? 'BUDGET' : 'TARGET';

  return (
    <>
      <div className="mb-8 flex w-full flex-row items-end justify-between">
        <div>
          <div className="text-[20px] font-medium tracking-normal">General</div>
          <div className="text-md font-normal text-neutral-500">
            Define your general portfolio settings, including overspend handling and optimization setup.
          </div>
        </div>
        <div className="flex h-fit gap-4">
          <Button variant="contained" onClick={openDiscardDialog} disabled={loading || !allowSubmit}>
            Discard changes
          </Button>
          <ButtonAsync
            variant="contained"
            color="primary"
            loading={loading}
            disabled={!allowSubmit}
            onClick={openApplyDialog}
          >
            Apply changes
          </ButtonAsync>
        </div>
      </div>
      <div className="max-w-5xl">
        <div>
          <WrapStyled>
            <Fieldset
              style={{
                marginBottom: 32,
              }}
            >
              <FormGroup>
                <TextField
                  id="title"
                  label="Name"
                  name="title"
                  value={form.title}
                  onChange={handleChange}
                  placeholder="Portfolio name"
                />
              </FormGroup>

              <FormGroup>
                <DateSelector
                  dateFrom={form.startDate}
                  dateTo={form.endDate}
                  onDateChange={handleDateChange}
                  disableAfterDate={null}
                  useNexoyaDateRanges
                  panelProps={{
                    placement: 'bottom-start',
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </FormGroup>
              {form.optimizationType !== budgetOptimizationType.SKIP ? (
                <>
                  <Typography
                    variant="h3"
                    style={{
                      marginBottom: 24,
                    }}
                  >
                    Risk level
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 20,
                    }}
                  >
                    {riskTypes[portfolioRiskType].map((item, index) => (
                      <BudgetOptimizationItem
                        key={index}
                        className={item.value === form.optimizationRiskLevel ? 'selectedOptimization' : ''}
                        data-cy={item.title}
                        style={{
                          width: '30%',
                        }}
                        onClick={() =>
                          handleChange({
                            target: {
                              name: 'optimizationRiskLevel',
                              value: item.value,
                            },
                          })
                        }
                      >
                        {mapRiskIcon[item.value]}
                        <Typography variant="h5">{item.title}</Typography>
                        <Typography variant="subtitlePill" withEllipsis={false}>
                          {item.description}
                        </Typography>
                      </BudgetOptimizationItem>
                    ))}
                  </div>
                </>
              ) : null}
              {/* Budget applying mode */}
              {isSupportUser ? (
                <>
                  <Typography
                    variant="h3"
                    style={{
                      marginTop: 24,
                      marginBottom: 24,
                    }}
                  >
                    Budget apply mode
                  </Typography>
                  <RadioGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {budgetProposalTargetBiddingApplyTypes.map((item) => (
                      <FormControlLabel
                        key={item.type}
                        checked={form.budgetProposalTargetBiddingApplyMode === item.type}
                        onChange={() =>
                          handleChange({
                            target: {
                              name: 'budgetProposalTargetBiddingApplyMode',
                              value: item.type,
                            },
                          })
                        }
                        value={item.type}
                        name="optimizationType"
                        label={item.title}
                        control={<Radio inputProps={{ 'aria-label': 'A' }} />}
                        data-cy={item.type}
                      />
                    ))}
                  </RadioGroup>
                </>
              ) : null}
            </Fieldset>
            {/* Budget delta */}
            {portfolio.type === NexoyaPortfolioType.Budget ? (
              <BudgetDeltasWrapper>
                <TextWrapper>
                  <Typography variant="h3">Budget delta</Typography>
                  <Typography withEllipsis={false} variant="subtitle">
                    How would you like Nexoya to handle deltas between planned budget vs. spent during a budget
                    allocation?
                  </Typography>
                  <Typography style={{ fontSize: 13 }} withEllipsis={false} variant="subtitle">
                    Note: Handling deltas will be done at every start of a new budget application. Changing the setting
                    will have an impact from the budget allocation onwards.
                  </Typography>
                </TextWrapper>
                <BudgetDelaOptionsWrapper>
                  {BUDGET_DELTA_OPTIONS.map((budgetDeltaOption) => (
                    <OptionCard
                      onClick={() =>
                        handleChange({
                          target: {
                            name: 'budgetDeltaHandlingPolicy',
                            value: budgetDeltaOption.id,
                          },
                        })
                      }
                      selected={form?.budgetDeltaHandlingPolicy === budgetDeltaOption.id}
                      key={budgetDeltaOption.id}
                    >
                      {budgetDeltaOption.image}
                      <Typography variant="paragraph" withEllipsis={false}>
                        {budgetDeltaOption.title}
                      </Typography>
                      <Typography style={{ fontSize: 13, fontWeight: 400 }} variant="subheadline" withEllipsis={false}>
                        {budgetDeltaOption.description}
                      </Typography>
                    </OptionCard>
                  ))}
                </BudgetDelaOptionsWrapper>
                <div style={{ color: nexyColors.blueyGrey, marginTop: 24 }}>
                  or{' '}
                  <Checkbox
                    disabled={false}
                    data-cy="ignoreBudgetDelta"
                    label="Ignore deltas. Don’t allocate budget."
                    checked={form?.budgetDeltaHandlingPolicy === NexoyaBudgetDeltaHandlingPolicy.Ignore}
                    onClick={() => {
                      handleChange({
                        target: {
                          name: 'budgetDeltaHandlingPolicy',
                          value: NexoyaBudgetDeltaHandlingPolicy.Ignore,
                        },
                      });
                    }}
                  />
                </div>
              </BudgetDeltasWrapper>
            ) : null}

            {/* Labels management */}
            {isSupportUser ? (
              <>
                <TextWrapper style={{ marginBottom: 24 }}>
                  <Typography variant="h3">Labels</Typography>
                  <Typography withEllipsis={false} variant="subtitle">
                    This setting allows defining the labels.
                  </Typography>
                  <Typography style={{ fontSize: 13 }} withEllipsis={false} variant="subtitle">
                    Note: Assigning contents to labels can be done in the ‘Content’ part of the application. The
                    configuration of the labels will not influence how budget is allocated to contents in order to
                    ensure an optimal performance per label.
                  </Typography>
                </TextWrapper>
                <LabelsEditTable
                  portfolioId={portfolioId}
                  labels={labelsState}
                  setLabels={setLabels}
                  handleEdit={handleEditLabels}
                  handleDelete={handleDeleteLabels}
                  loadingUpdate={false}
                  loadingDelete={false}
                />
              </>
            ) : null}
            {/* Skip training days */}
            {isSupportUser ? (
              <>
                <Typography
                  variant="h3"
                  style={{
                    marginTop: 24,
                    marginBottom: 24,
                  }}
                >
                  Skip training days for optimizations
                </Typography>
                <Button
                  id="riskLevelBtn"
                  onClick={toggleSkipTrainingMenu}
                  variant="contained"
                  color="secondary"
                  flat
                  type="button"
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  endAdornment={
                    <ButtonAdornment position="end">
                      <SvgCaretDown
                        style={{
                          transform: `rotate(${isSkipTrainingMenuOpen ? '180' : '0'}deg)`,
                        }}
                      />
                    </ButtonAdornment>
                  }
                  ref={skipTrainingAnchor}
                >
                  {form.skipTrainingDays}
                </Button>
                <Panel
                  open={isSkipTrainingMenuOpen}
                  color="dark"
                  anchorEl={skipTrainingAnchor.current}
                  placement="bottom-start"
                  style={{
                    minWidth: 100,
                    maxHeight: 500,
                  }}
                  popperProps={{
                    style: {
                      zIndex: 1301,
                    },
                  }}
                >
                  <MenuList>
                    {[0, 1, 2, 3].map((value) => (
                      <MenuItem
                        key={value}
                        buttonProps={{ style: { background: 'none', justifyContent: 'center' } }}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'skipTrainingDays',
                              value,
                            },
                          });
                          closeSkipTrainingMenu();
                        }}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Panel>
              </>
            ) : null}
            {/*   n  */}
            {isSupportUser ? (
              <div style={{ marginTop: 40 }}>
                {metabaseLinks.map((mb, idx) => (
                  <div key={idx} style={{ display: 'flex', gap: '12px' }}>
                    <FormGroup>
                      <TextField
                        id={`metabaseName-${idx}`}
                        label="Metabase Name"
                        placeholder="Overview"
                        name="name"
                        value={mb.name}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setMetabaseLinks((prevState) => {
                            const newState = [...prevState];
                            newState[idx] = { ...newState[idx], [name]: value };
                            return newState;
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        id={`metabaseUrl-${idx}`}
                        label="Metabase URL"
                        placeholder="https://metabase.nexoya.io/api/public/dashboard/1"
                        name="url"
                        value={mb.url}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setMetabaseLinks((prevState) => {
                            const newState = [...prevState];
                            newState[idx] = { ...newState[idx], [name]: value };
                            return newState;
                          });
                        }}
                      />
                    </FormGroup>
                    <DropdownMenu>
                      <DropdownMenuTrigger className="m-auto" disabled={loading} asChild>
                        <ShadcnButton className="rounded-full" variant="ghost" size="sm">
                          <SvgEllipsisV
                            style={{
                              fontSize: 18,
                            }}
                          />
                        </ShadcnButton>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-52 font-medium" align="start">
                        <DropdownMenuItem
                          disabled={metabaseLinks?.length === 1 && idx === 0 && mb.name === '' && mb.url === ''}
                          onSelect={() => {
                            if (metabaseLinks?.length === 1 && idx === 0 && (mb.name || mb.url)) {
                              setMetabaseLinks([{ name: '', url: '' }]);
                            } else {
                              setMetabaseLinks((currentLinks) => currentLinks.filter((_, linkIdx) => idx !== linkIdx));
                            }
                          }}
                        >
                          <span className="text-red-400">Delete metabase name</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                ))}
                <Button
                  style={{ marginBottom: 24 }}
                  color="secondary"
                  variant="contained"
                  endAdornment={
                    <ButtonAdornment>
                      <SvgPlusRegular />
                    </ButtonAdornment>
                  }
                  onClick={() => setMetabaseLinks((currentLinks) => [...currentLinks, { name: '', url: '' }])}
                >
                  Add More
                </Button>
              </div>
            ) : null}
          </WrapStyled>
        </div>
        {error ? <ErrorMessage error={error} /> : null}
        <ConfirmationDialog
          description="Your changes will apply to the entire setup in your portfolio."
          warning={getWarningMessageIfChangingDates()}
          onConfirm={handleSubmit}
          type="apply"
          isOpen={isApplyOpen}
          onCancel={closeApplyDialog}
          disabled={loading}
        />
        <ConfirmationDialog
          description="Your changes will be discarded. The settings will revert to the current active setup in your portfolio."
          onConfirm={() => {
            setForm(initialState);
            resetLabels();
            closeDiscardDialog();
          }}
          type="discard"
          isOpen={isDiscardOpen}
          onCancel={closeDiscardDialog}
        />
      </div>
    </>
  );
}

export default withRouter(PortfolioGeneralSettings);

import { gql, InternalRefetchQueryDescriptor, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  NexoyaApplyRulesToDiscoveredContentsMutationResponse,
  NexoyaApplyRulesToDiscoveredContentsRulesMapInput,
  NexoyaDiscoveredContentStatus,
} from '../../types';
import { useTeam } from '../../context/TeamProvider';
import { DISCOVERED_CONTENTS_QUERY } from './queryDiscoveredContents';

const APPLY_RULES_TO_DISCOVERED_CONTENTS_MUTATION = gql`
  mutation ApplyRulesToDiscoveredContents(
    $discoveredContentsWithRulesToApply: [ApplyRulesToDiscoveredContentsRulesMapInput!]!
    $portfolioId: Float!
    $teamId: Float!
  ) {
    applyRulesToDiscoveredContents(
      discoveredContentsWithRulesToApply: $discoveredContentsWithRulesToApply
      portfolioId: $portfolioId
      teamId: $teamId
    ) {
      appliedContentRules
      appliedImpactGroupRules
    }
  }
`;

type ApplyRulesToDiscoveredContentsProps = {
  discoveredContentsWithRulesToApply: NexoyaApplyRulesToDiscoveredContentsRulesMapInput[];
  portfolioId: number;
  teamId: number;
};

type ApplyRulesToDiscoveredContentsResponse = {
  applyRulesToDiscoveredContents: NexoyaApplyRulesToDiscoveredContentsMutationResponse;
};

export function useApplyRulesToDiscoveredContentsMutation({
  portfolioId,
  status,
  refetchQueries = [],
}: {
  portfolioId: number;
  status: NexoyaDiscoveredContentStatus;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) {
  const { teamId } = useTeam();

  return useMutation<ApplyRulesToDiscoveredContentsResponse, ApplyRulesToDiscoveredContentsProps>(
    APPLY_RULES_TO_DISCOVERED_CONTENTS_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: (error) => {
        console.error('Error applying rules to discovered contents:', error);
        toast.error(error.message || 'Failed to apply rules to discovered contents');
      },
      onCompleted: (data) => {
        toast.success(
          `${data.applyRulesToDiscoveredContents.appliedContentRules} content rules and ${data.applyRulesToDiscoveredContents.appliedImpactGroupRules} impact group rules applied successfully`,
        );
      },
      refetchQueries: [
        {
          notifyOnNetworkStatusChange: true,
          query: DISCOVERED_CONTENTS_QUERY,
          variables: {
            status,
            teamId,
            portfolioId,
          },
          fetchPolicy: 'network-only',
        },
        ...refetchQueries,
      ],
    },
  );
}

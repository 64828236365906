import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  NexoyaContentRuleFunnelStepMappingInput,
  NexoyaSetContentRuleMappingAndApplyToContentsMutationResponse,
} from '../../types';
import { CONTENT_RULES_QUERY } from './queryContentRules';
import { useTeam } from '../../context/TeamProvider';
import { PORTFOLIO_QUERY } from '../portfolio/queryPortfolio';
import { StringParam, useQueryParams } from 'use-query-params';
import { UTC_TIMEZONE } from '../../utils/dates';

const SET_CONTENT_RULE_MAPPING_MUTATION = gql`
  mutation SetContentRuleMappingAndApplyToContents(
    $contentRuleId: Float!
    $funnelStepMappings: [ContentRuleFunnelStepMappingInput!]!
    $portfolioId: Float!
    $teamId: Float!
  ) {
    setContentRuleMappingAndApplyToContents(
      contentRuleId: $contentRuleId
      funnelStepMappings: $funnelStepMappings
      portfolioId: $portfolioId
      teamId: $teamId
    ) {
      clashingDiscoveredContents {
        discoveredContentId
        contentRules {
          isApplied
          contentRule {
            contentRuleId
            name
          }
        }
        impactGroupRules {
          isApplied
          impactGroupRule {
            impactGroupRuleId
            name
          }
        }
        content {
          portfolioContentId(portfolioId: $portfolioId)
          contentId
          title
          provider {
            provider_id
            name
          }
          contentType {
            name
          }
          parent {
            title
          }
        }
      }
      contentRule {
        name
        contentRuleId
        appliedDiscoveredContents {
          discoveredContentId
          content {
            portfolioContentId(portfolioId: $portfolioId)
            contentId
            title
            provider {
              provider_id
              name
            }
            contentType {
              name
            }
            parent {
              title
            }
          }
        }
      }
    }
  }
`;

type SetContentRuleMappingMutationProps = {
  contentRuleId: number;
  funnelStepMappings: NexoyaContentRuleFunnelStepMappingInput[];
  portfolioId: number;
  teamId: number;
};

export function useSetContentRuleMappingMutation({ onCompleted, portfolioId }) {
  const { teamId } = useTeam();
  const [qp] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
  });

  return useMutation<
    { setContentRuleMappingAndApplyToContents: NexoyaSetContentRuleMappingAndApplyToContentsMutationResponse },
    SetContentRuleMappingMutationProps
  >(SET_CONTENT_RULE_MAPPING_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error('Error setting content rule mapping:', error);
      toast.error(error.message || 'Failed to set content rule mapping');
    },
    onCompleted,
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: CONTENT_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId,
          withBudget: false,
          dateFrom: qp.dateFrom + UTC_TIMEZONE,
          dateTo: qp.dateTo + UTC_TIMEZONE,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}

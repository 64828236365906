import React, { useState } from 'react';
import { get } from 'lodash';

import { NexoyaDiscoveredContentStatus, NexoyaPortfolio, NexoyaPortfolioContentDetail } from 'types';
import { useAddManyContentRelationsMutation } from '../../../../graphql/portfolio/mutationUpdateContentRelation';
import { KpisFilterProvider2 } from 'context/KpisFilterProvider';

import { copyToClipboard } from 'utils/helpers';
import { Button } from '../../../../components-ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../../components-ui/DropdownMenu';
import SvgEllipsisV from '../../../../components/icons/EllipsisV';
import ErrorMessage from '../../../../components/ErrorMessage';
import { ContentManageBudgetDialog } from '../ContentManageBudgetDialog';
import LinkPortfolioContentDialog from '../LinkPortfolioContentDialog';
import { ContentOptimizationDialog } from './ContentOptimizationDialog';
import ContentRemoveDialog from './ContentRemoveDialog';
import { useApplyRulesToDiscoveredContentsMutation } from '../../../../graphql/portfolioRules/mutationApplyRulesToDiscoveredContents';
import { toast } from 'sonner';
import { ChangeRulesDialog } from './ChangeRulesDialog';
import { useTeam } from '../../../../context/TeamProvider';
import PortfolioFeatureSwitch from '../../../../components/PortfolioFeatureSwitch';
import { PORTFOLIO_FEATURE_FLAGS } from '../../../../constants/featureFlags';
import { AssignRulesDialog } from './AssignRulesDialog';
import { PORTFOLIO_QUERY } from '../../../../graphql/portfolio/queryPortfolio';
import dayjs from 'dayjs';

type Props = {
  item: NexoyaPortfolioContentDetail;
  portfolio: NexoyaPortfolio;
  dateFrom: Date;
  dateTo: Date | string;
};

function ContentTableRowTDM({ item, portfolio, dateFrom, dateTo }: Props) {
  const { teamId } = useTeam();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isOptimizationDialogOpen, setOptimizationDialogOpen] = useState(false);
  const [isBudgetDialogOpen, setBudgetDialogOpen] = useState(false);
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [contentId, setContentId] = useState<string>();
  const [isChangeRulesOpen, setChangeRulesOpen] = useState(false);
  const [isAssignRulesOpen, setAssignRulesOpen] = useState(false);

  const [addManyContentRelations, { loading, error }] = useAddManyContentRelationsMutation({
    dateFrom,
    dateTo,
    portfolioId: portfolio.portfolioId,
    contentRelations: [
      {
        contentId: +contentId,
        belongsToContentId: item?.contentId,
      },
    ],
  });

  const [applyRulesToDiscoveredContents, { loading: loadingApply }] = useApplyRulesToDiscoveredContentsMutation({
    portfolioId: portfolio.portfolioId,
    status: NexoyaDiscoveredContentStatus.AcceptedButHasUnappliedRules,
    refetchQueries: [
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId: portfolio?.portfolioId,
          withBudget: false,
          dateFrom: dayjs(dateFrom)?.utc().toDate(),
          dateTo: dayjs(dateTo)?.utc().toDate(),
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const handleChangeRules = async ({ contentRuleId, impactGroupRuleId }) => {
    try {
      await applyRulesToDiscoveredContents({
        variables: {
          discoveredContentsWithRulesToApply: [
            {
              discoveredContentId: item.discoveredContent?.discoveredContentId,
              contentRuleId,
              impactGroupRuleId,
            },
          ],
          portfolioId: portfolio.portfolioId,
          teamId,
        },
      });
      setChangeRulesOpen(false);
    } catch (error) {
      console.error('Error changing rules:', error);
      toast.error('Failed to change rules');
    }
  };

  async function handleSubmit() {
    try {
      const res = await addManyContentRelations();
      const success = get(res, 'data.addManyContentRelations', false);
      if (success) {
        setSidePanelOpen(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="rounded-full" variant="ghost" size="sm">
          <SvgEllipsisV style={{ fontSize: 18 }} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-52 font-normal" align="start">
        <DropdownMenuItem onSelect={() => setSidePanelOpen(true)}>Link to</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => copyToClipboard(item?.contentId?.toString())}>Copy ID</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => setBudgetDialogOpen(true)}>Manage budget limit</DropdownMenuItem>
        <>
          <PortfolioFeatureSwitch
            features={[PORTFOLIO_FEATURE_FLAGS.SELF_SERVICE_PORTFOLIO]}
            renderOld={() => null}
            renderNew={() => (
              <>
                <DropdownMenuSeparator />

                {item.discoveredContent?.status !== NexoyaDiscoveredContentStatus.Manual && (
                  <DropdownMenuItem onSelect={() => setChangeRulesOpen(true)}>Change rules</DropdownMenuItem>
                )}
                {item.discoveredContent?.status === NexoyaDiscoveredContentStatus.Manual && (
                  <DropdownMenuItem onSelect={() => setAssignRulesOpen(true)}>Switch to rule-based</DropdownMenuItem>
                )}
                <DropdownMenuSeparator />
              </>
            )}
          />
        </>
        <DropdownMenuItem onSelect={() => setOptimizationDialogOpen(true)}>
          {item.isIncludedInOptimization ? 'Disable' : 'Enable'} optimization
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => setDeleteDialogOpen(true)} className="text-red-400">
          Delete content
        </DropdownMenuItem>
      </DropdownMenuContent>

      <KpisFilterProvider2>
        {isSidePanelOpen && (
          <LinkPortfolioContentDialog
            isOpen={isSidePanelOpen}
            loading={loading}
            parentId={item.contentId}
            contentId={contentId}
            setContentId={setContentId}
            onSubmit={handleSubmit}
            onClose={() => setSidePanelOpen(false)}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        )}

        {error && <ErrorMessage error={error} />}
      </KpisFilterProvider2>

      {isDeleteDialogOpen && (
        <ContentRemoveDialog
          isOpen={isDeleteDialogOpen}
          toggleDialog={() => setDeleteDialogOpen(false)}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}

      {isOptimizationDialogOpen && (
        <ContentOptimizationDialog
          isOpen={isOptimizationDialogOpen}
          toggleDialog={() => setOptimizationDialogOpen(false)}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}

      {isBudgetDialogOpen && (
        <ContentManageBudgetDialog
          isOpen={isBudgetDialogOpen}
          toggleDialog={() => setBudgetDialogOpen(false)}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
      {isChangeRulesOpen && (
        <ChangeRulesDialog
          isOpen={isChangeRulesOpen}
          onClose={() => setChangeRulesOpen(false)}
          item={item}
          onConfirm={handleChangeRules}
          loading={loadingApply}
        />
      )}
      {isAssignRulesOpen && (
        <AssignRulesDialog
          isOpen={isAssignRulesOpen}
          onClose={() => setAssignRulesOpen(false)}
          item={item}
          onConfirm={handleChangeRules}
          loading={loadingApply}
        />
      )}
    </DropdownMenu>
  );
}

export default ContentTableRowTDM;

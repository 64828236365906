import { gql, useQuery } from '@apollo/client';
import { useTeam } from 'context/TeamProvider';
import { NexoyaContentFilterInput, NexoyaContentV2 } from '../../types';

export const FILTERED_CONTENTS_QUERY = gql`
  query FilteredContents(
    $portfolioId: Float!
    $teamId: Float!
    $filters: [ContentFilterInput!]!
    $excludePortfolioContents: Boolean!
  ) {
    filterContents(
      portfolioId: $portfolioId
      teamId: $teamId
      filters: $filters
      excludePortfolioContents: $excludePortfolioContents
    ) {
      portfolioContentId(portfolioId: $portfolioId)
      contentId
      title
      latestMeasurementDataDate
      startDatetime
      endDatetime
      biddingStrategy {
        type
        value
      }
      budget {
        value
        type
        shared
      }
      status
      provider {
        provider_id
        name
      }
      contentType {
        collection_type_id
        name
      }
      parent {
        contentId
        title
      }
    }
  }
`;

type ContentRuleQueryVariables = {
  teamId?: number;
  portfolioId: number;
  excludePortfolioContents?: boolean;
  filters: NexoyaContentFilterInput[];
  skip?: boolean;
  onCompleted?: (data: { filterContents: NexoyaContentV2[] }) => void;
  onError?: (error: Error) => void;
};

export function useFilteredContentsQuery({
  portfolioId,
  filters,
  skip,
  onCompleted,
  excludePortfolioContents,
}: ContentRuleQueryVariables) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      filterContents: NexoyaContentV2[];
    },
    ContentRuleQueryVariables
  >(FILTERED_CONTENTS_QUERY, {
    variables: {
      teamId,
      portfolioId,
      filters,
      excludePortfolioContents,
    },
    // fetchPolicy: 'network-only',
    skip,
    onCompleted,
  });

  return query;
}

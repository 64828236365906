import { create } from 'zustand';
import { NexoyaDiscoveredContent } from '../types';

export interface SelectedRule {
  contentRuleId: string | null;
  impactGroupRuleId: string | null;
  // Additional rule types (e.g. metricId/impactGroupId) can be added here.
}

interface DiscoveredContentStore {
  acceptedDiscoveredContents: NexoyaDiscoveredContent[];
  filteredContents: NexoyaDiscoveredContent[];
  selectedContentIds: number[];
  discoveredContentsActiveSwitch: string;
  // For discovered content rules (renamed from selectedRules)
  selectedDiscoveredContentRules: Record<number, SelectedRule>;
  // For unapplied content rules (renamed from selectedContentRules)
  selectedUnappliedContentRules: Record<number, SelectedRule>;
  // This state is shared from the unapplied rules store.
  contentsWithUnappliedRules: NexoyaDiscoveredContent[];
  // New property to track update flags per tab.
  tabNewUpdates: {
    discoveredContents: boolean;
    unappliedRules: boolean;
  };
  // Actions for discovered contents.
  setAcceptedDiscoveredContents: (contents: NexoyaDiscoveredContent[]) => void;
  setFilteredContents: (contents: NexoyaDiscoveredContent[]) => void;
  addSelectedContentId: (id: number) => void;
  removeSelectedContentId: (id: number) => void;
  resetSelectedContentIds: () => void;
  setDiscoveredContentsActiveSwitch: (switchId: string) => void;
  // Action for discovered content rules.
  setSelectedDiscoveredContentRules: (
    contentId: number,
    ruleType: 'metricId' | 'impactGroupId' | 'contentRuleId' | 'impactGroupRuleId',
    value: string | null,
  ) => void;
  // Actions from the unapplied rules store.
  setContentsWithUnappliedRules: (contents: NexoyaDiscoveredContent[]) => void;
  setSelectedUnappliedContentRules: (
    contentId: number,
    ruleType: 'contentRuleId' | 'impactGroupRuleId',
    value: string | null,
  ) => void;
  resetSelectedUnappliedContentRules: () => void;
  // New actions for update flags.
  setTabNewUpdates: (tab: 'discoveredContents' | 'unappliedRules', value: boolean) => void;
  resetTabNewUpdates: () => void;
}

export const useDiscoverContentsStore = create<DiscoveredContentStore>((set) => ({
  acceptedDiscoveredContents: [],
  filteredContents: [],
  selectedContentIds: [],
  discoveredContentsActiveSwitch: 'to-review',
  // Discovered content rules (renamed)
  selectedDiscoveredContentRules: {},
  // Unapplied content rules (renamed)
  selectedUnappliedContentRules: {},
  contentsWithUnappliedRules: [],
  tabNewUpdates: {
    discoveredContents: false,
    unappliedRules: false,
  },
  // Discovered contents actions.
  setAcceptedDiscoveredContents: (contents) => set(() => ({ acceptedDiscoveredContents: contents })),
  setFilteredContents: (contents) => set(() => ({ filteredContents: contents })),
  addSelectedContentId: (id) => set((state) => ({ selectedContentIds: [...state.selectedContentIds, id] })),
  removeSelectedContentId: (id) =>
    set((state) => ({
      selectedContentIds: state.selectedContentIds.filter((contentId) => contentId !== id),
    })),
  resetSelectedContentIds: () => set(() => ({ selectedContentIds: [] })),
  setDiscoveredContentsActiveSwitch: (switchId) => set(() => ({ discoveredContentsActiveSwitch: switchId })),
  // Discovered content rules action.
  setSelectedDiscoveredContentRules: (contentId, ruleType, value) =>
    set((state) => ({
      selectedDiscoveredContentRules: {
        ...state.selectedDiscoveredContentRules,
        [contentId]: {
          ...state.selectedDiscoveredContentRules[contentId],
          [ruleType]: value,
        },
      },
    })),
  // Unapplied rules actions.
  setContentsWithUnappliedRules: (contents) => set(() => ({ contentsWithUnappliedRules: contents })),
  setSelectedUnappliedContentRules: (contentId, ruleType, value) =>
    set((state) => ({
      selectedUnappliedContentRules: {
        ...state.selectedUnappliedContentRules,
        [contentId]: {
          ...state.selectedUnappliedContentRules[contentId],
          [ruleType]: value,
        },
      },
    })),
  resetSelectedUnappliedContentRules: () => set(() => ({ selectedUnappliedContentRules: {} })),
  // Update flag actions.
  setTabNewUpdates: (tab, value) =>
    set((state) => ({
      tabNewUpdates: { ...state.tabNewUpdates, [tab]: value },
    })),
  resetTabNewUpdates: () =>
    set(() => ({
      tabNewUpdates: { discoveredContents: false, unappliedRules: false },
    })),
}));

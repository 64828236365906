import {
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaContentRule,
  NexoyaContentV2,
  NexoyaDeleteContentRuleUserChoiceInput,
  NexoyaDeleteImpactGroupRuleUserChoiceInput,
  NexoyaFunnelStepMappingType,
  NexoyaFunnelStepV2,
  NexoyaImpactGroupRule,
  NexoyaProvider,
} from '../../../types';
import { nexyColors } from '../../../theme';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import ButtonIcon from '../../../components/ButtonIcon';
import SvgEllipsisV from '../../../components/icons/EllipsisV';
import AvatarProvider from '../../../components/AvatarProvider';
import translate from '../../../utils/translate';
import { capitalize, toNumber, truncate } from 'lodash';
import Tooltip from '../../../components/Tooltip';
import {
  displayFilterValue,
  excludeProviderAndParentCollectionFields,
  getHumanReadableFunnelStepMapping,
  getIconForField,
  humanizeFieldName,
  OPERATORS_MAP,
} from '../components/Content/ContentRule/utils';
import { LabelLight } from '../../../components/InputLabel/styles';
import { ContentMetricAssignment } from '../components/Content/ContentRule/ContentMetricAssignment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useProviders } from '../../../context/ProvidersProvider';
import { DeleteRule } from './DeleteRule';
import { useDialogState } from '../../../components/Dialog';
import { CONTENT_TYPE_SUB_ACCOUNT_NUMBER, extractProviderMapFromFilters } from '../utils/portfolio-rules';
import { useContentMappingStore } from '../../../store/content-metric-assignments';
import { useLazyQuery } from '@apollo/client';
import { MEASUREMENTS_QUERY } from '../../../graphql/measurement/queryMeasurements';
import { useTeam } from '../../../context/TeamProvider';
import { HoverableTooltip } from '../../../components-ui/HoverCard';
import Link2 from '../../../components/icons/Link2';
import { usePortfolio } from '../../../context/PortfolioProvider';
import { cn } from '../../../lib/utils';
import SvgNoFillPencil from '../../../components/icons/NoFillPencil';
import { RuleDialog } from './RuleDialog';
import useTranslationStore from '../../../store/translations';
import { BrickLoader } from '../../Portfolio';
import { useProviderSubAccountsQuery } from '../../../graphql/portfolioRules/queryProviderSubAccounts';

type NexoyaRule = NexoyaContentRule | NexoyaImpactGroupRule;

interface Props {
  config: {
    ruleType: 'content-rule' | 'impact-group-rule';
    update: {
      mutation: any;
      loading: boolean;
    };
  };
  contentMetricAssignment?: {
    rule: NexoyaContentRule;
    isOpen: boolean;
    close: () => void;
    open: (rule: NexoyaContentRule) => void;
  };
  rule: NexoyaRule;
  ruleId: number;
  handleEditRule: (rule: NexoyaRule) => void;
  handleDeleteRule: (rule: NexoyaRule) => void;
  handleDuplicateRule: (rule: NexoyaRule) => void;
  funnelSteps: NexoyaFunnelStepV2[];
  userChoices: NexoyaDeleteContentRuleUserChoiceInput[] | NexoyaDeleteImpactGroupRuleUserChoiceInput[];
  setUserChoices: Dispatch<
    SetStateAction<NexoyaDeleteContentRuleUserChoiceInput[] | NexoyaDeleteImpactGroupRuleUserChoiceInput[]>
  >;
  resetUserChoices: () => void;
  loadingDelete: boolean;
}

export const PortfolioRuleCard = ({
  rule,
  ruleId,
  handleEditRule,
  handleDeleteRule,
  handleDuplicateRule,
  funnelSteps,
  loadingDelete,
  setUserChoices,
  userChoices,
  resetUserChoices,
  config,
  contentMetricAssignment,
}: Props) => {
  const { teamId } = useTeam();
  const { activeProviders } = useProviders();

  const [subAccounts, setSubAccounts] = useState<NexoyaContentV2[]>([]);

  const isImpactGroupRule = rule.__typename === 'ImpactGroupRule';

  const [name, setName] = useState(rule?.name);
  const [selectedImpactGroupId, setSelectedImpactGroupId] = useState<number | null>(
    rule.__typename === 'ImpactGroupRule' ? rule?.impactGroupId : null,
  );

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const impactGroups = portfolioMeta?.impactGroups || [];
  const portfolioId = portfolioMeta?.portfolioId;

  const { measurementsByProvider, setMeasurementsByProvider } = useContentMappingStore();
  const { translations } = useTranslationStore();

  const measurements = Object.values(measurementsByProvider).flat();

  const { loading: subAccountsLoading } = useProviderSubAccountsQuery({
    teamId,
    portfolioId,
    filters: [
      {
        fieldName: NexoyaContentFilterFieldName.ContentType,
        operator: NexoyaContentFilterOperator.Eq,
        value: { number: CONTENT_TYPE_SUB_ACCOUNT_NUMBER },
      },
    ],
    excludePortfolioContents: false,
    onCompleted: (data) => setSubAccounts(data?.filterContents),
  });

  const [fetchMeasurements, { loading: measurementsLoading }] = useLazyQuery(MEASUREMENTS_QUERY);

  const { isOpen: isOpenDeleteDialog, toggleDialog, closeDialog: closeDeleteDialog } = useDialogState();
  const { isOpen: isOpenRuleDialog, toggleDialog: toggleRuleDialog, closeDialog: closeRuleDialog } = useDialogState();

  const [dialogMode, setDialogMode] = useState<'content-name' | 'impact-name' | 'impact-assign'>('content-name');

  const handleOpenDialog = (mode: 'content-name' | 'impact-name' | 'impact-assign') => {
    setDialogMode(mode);
    toggleRuleDialog();
  };

  useEffect(() => {
    if (subAccountsLoading) return;

    const providerMap = Object.entries(extractProviderMapFromFilters(rule.filters, subAccounts));

    if (!providerMap?.length) return;

    const fetchData = async () => {
      providerMap.map(async ([providerIdString]) => {
        const providerId = toNumber(providerIdString);

        const { data: measurementsData } = await fetchMeasurements({
          variables: { providerId },
        });

        if (measurementsData?.measurements) {
          setMeasurementsByProvider(providerId, measurementsData.measurements);
        }
      });
    };

    if (providerMap?.length) {
      fetchData();
    }
  }, [ruleId, subAccounts]);

  const handleUpdate = () => {
    const isImpactGroupRule = rule.__typename === 'ImpactGroupRule';

    const ruleId = isImpactGroupRule
      ? { impactGroupRuleId: rule.impactGroupRuleId }
      : { contentRuleId: rule.contentRuleId };

    const previewVariables = {
      portfolioId,
      teamId,
      name,
      ...(isImpactGroupRule
        ? { impactGroupRuleEdit: { name, impactGroupId: selectedImpactGroupId }, contentRuleEdit: {} }
        : { contentRuleEdit: { name }, impactGroupRuleEdit: {} }),
      ...ruleId,
    };

    config.update.mutation({ variables: previewVariables }).then(() => closeRuleDialog());
  };

  const getSelectedProvider = (providerId: number) =>
    activeProviders.find((provider: NexoyaProvider) => provider.provider_id === providerId);

  const getImpactGroupName = (impactGroupId: number) =>
    portfolioMeta?.impactGroups?.find((ig) => ig.impactGroupId === impactGroupId)?.name;

  return (
    <div className="rounded-[5px] border border-neutral-100" key={ruleId}>
      <div className="flex items-center justify-between rounded-t-[5px] border-b border-neutral-100 bg-seasalt p-4">
        <div>
          <div className="flex items-center gap-0 text-lg text-neutral-900" style={{ color: nexyColors.neutral900 }}>
            <div className="flex items-center">
              {rule.name}
              <ButtonIcon
                style={{ marginLeft: 4 }}
                onClick={() => handleOpenDialog(isImpactGroupRule ? 'impact-name' : 'content-name')}
              >
                <SvgNoFillPencil />
              </ButtonIcon>
            </div>
            {/* Warning icon if not all funnel steps have an assigned metric */}
            {rule.__typename === 'ContentRule' && rule?.funnelStepMappings?.length !== funnelSteps?.length ? (
              <Tooltip
                placement="right"
                style={{ maxWidth: 400 }}
                variant="dark"
                content="Not all funnel steps have assigned metrics"
              >
                <span>
                  <Link2 className="ml-3 h-7 w-7" />
                </span>
              </Tooltip>
            ) : null}
          </div>
          {/* Impact group name if the rule is an Impact group rule */}
          {rule.__typename === 'ImpactGroupRule' ? (
            <span className="text-[11px] font-semibold uppercase tracking-wider text-neutral-400">
              {getImpactGroupName(rule.impactGroupId)}
            </span>
          ) : null}
        </div>
        <div className="flex items-center gap-3">
          <div
            onClick={() => (rule.matchingDiscoveredContentsCount ? handleEditRule(rule) : null)}
            className={cn(
              'cursor-pointer text-xs font-medium text-purple-400 underline',
              !rule.matchingDiscoveredContentsCount ? 'cursor-not-allowed opacity-50' : '',
            )}
          >
            See {rule.matchingDiscoveredContentsCount} matching contents in portfolio
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <ButtonIcon
                style={{
                  fontSize: 18,
                }}
              >
                <SvgEllipsisV />
              </ButtonIcon>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="font-normal" align="end">
              <DropdownMenuItem onSelect={() => handleEditRule(rule)}>Edit filters</DropdownMenuItem>
              {!isImpactGroupRule ? (
                <DropdownMenuItem onSelect={() => contentMetricAssignment.open(rule)}>Edit metrics</DropdownMenuItem>
              ) : null}
              {isImpactGroupRule ? (
                <DropdownMenuItem onSelect={() => handleOpenDialog('impact-assign')}>
                  Assign impact group
                </DropdownMenuItem>
              ) : null}
              <DropdownMenuItem onSelect={() => handleDuplicateRule(rule)}>Duplicate</DropdownMenuItem>
              <DropdownMenuItem onSelect={toggleDialog} className="text-red-400">
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="p-3">
        <div className="mb-4 text-neutral-500">Channel and filter selection</div>
        <div className="flex gap-2">
          {Object.entries(extractProviderMapFromFilters(rule.filters, subAccounts)).map(
            ([providerId, adAccountIds]) => (
              <div
                key={providerId + adAccountIds}
                className="w-fit gap-1.5 truncate rounded-[5px] border border-[#D2D3DA] px-3 py-1.5"
              >
                <Tooltip
                  variant="dark"
                  placement="bottom-start"
                  style={{ wordBreak: 'break-word', maxWidth: 500 }}
                  content={
                    <>
                      <div className="flex items-center gap-3">
                        <AvatarProvider variant="circle" providerId={providerId} size={16} color="dark" />
                        {translate(translations, getSelectedProvider(toNumber(providerId))?.name)}
                      </div>
                      <div className="flex w-full flex-col text-neutral-200">
                        {adAccountIds?.map((adAccountId) => (
                          <div key={adAccountId} className="flex w-full flex-row gap-2">
                            <span className="text-neutral-400">&#x2022;</span>
                            {translate(translations, subAccounts.find((c) => c.contentId === adAccountId)?.title)}
                          </div>
                        ))}
                      </div>
                    </>
                  }
                >
                  <div className="flex items-center gap-3">
                    <AvatarProvider variant="circle" providerId={providerId} size={16} color="dark" />
                    {translate(translations, getSelectedProvider(toNumber(providerId))?.name)}
                    {adAccountIds ? (
                      <>
                        :
                        <span className="text-neutral-400">
                          {adAccountIds?.map((adAccountId) =>
                            truncate(
                              translate(translations, subAccounts.find((c) => c.contentId === adAccountId)?.title),
                              {
                                length: 10,
                              },
                            ),
                          )}
                        </span>
                      </>
                    ) : null}
                  </div>
                </Tooltip>
              </div>
            ),
          )}
          {rule.filters?.contentFilters?.filter(excludeProviderAndParentCollectionFields).map((filter, idx) => (
            <div key={idx} className="w-fit gap-1.5 truncate rounded-[5px] border border-[#D2D3DA] px-3 py-1.5">
              <Tooltip
                popperProps={{
                  style: { zIndex: 38000 },
                  modifiers: {
                    offset: {
                      offset: '0, 14',
                    },
                  },
                }}
                variant="dark"
                size="small"
                placement="top"
                content={`${capitalize(humanizeFieldName(filter.fieldName))} ${
                  OPERATORS_MAP[filter.operator]?.humanReadable
                }: ${displayFilterValue(filter)}`}
              >
                <div>
                  <span className="mr-2 text-neutral-400">{getIconForField(filter.fieldName)}</span>
                  <span className="font-light capitalize">{humanizeFieldName(filter.fieldName)}</span>
                  <>
                    <span className="font-light">: </span>
                    <span className="font-light text-muted-foreground">{displayFilterValue(filter)}</span>
                  </>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      {!isImpactGroupRule ? (
        measurementsLoading || subAccountsLoading ? (
          <div className="p-3">
            <div className="mb-4 text-neutral-500">Metric assignment</div>
            <div className="flex gap-8">
              {funnelSteps?.map((funnelStep) => (
                <div key={funnelStep.funnelStepId} className="w-fit gap-1.5 truncate">
                  <LabelLight>{funnelStep.title}</LabelLight>
                  <BrickLoader className="!h-4 !w-24" />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-3">
            <div className="mb-4 text-neutral-500">Metric assignment</div>
            <div className="flex gap-8">
              {funnelSteps?.map((funnelStep) => {
                const funnelStepMapping = rule.funnelStepMappings?.find(
                  (fsm) => fsm.funnelStepId === funnelStep.funnelStepId,
                );
                const { metric, mappingType } = getHumanReadableFunnelStepMapping({
                  funnelStepMapping,
                  measurements,
                  translations,
                });
                return (
                  <div key={funnelStep.funnelStepId} className="w-fit gap-1.5 truncate">
                    <LabelLight>{funnelStep.title}</LabelLight>
                    <Tooltip
                      style={{ wordBreak: 'break-word', maxWidth: 400 }}
                      popperProps={{
                        style: { zIndex: 38000 },
                        modifiers: {
                          offset: {
                            offset: '0, 14',
                          },
                        },
                      }}
                      variant="dark"
                      size="small"
                      placement="bottom"
                      content={`Mapping type: ${mappingType ? mappingType : 'No assignment'}`}
                    >
                      <HoverableTooltip className="w-fit max-w-fit cursor-none">
                        <span className={`text-xs font-light ${mappingType ? 'text-neutral-800' : 'text-neutral-300'}`}>
                          {funnelStepMapping?.mapping?.type === NexoyaFunnelStepMappingType.Ignore
                            ? 'No metric'
                            : funnelStepMapping?.mapping?.type === NexoyaFunnelStepMappingType.Utm
                              ? mappingType
                              : metric}
                        </span>
                      </HoverableTooltip>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        )
      ) : null}

      <RuleDialog
        isOpen={isOpenRuleDialog}
        onClose={closeRuleDialog}
        mode={dialogMode}
        name={name}
        setName={setName}
        selectedImpactGroupId={selectedImpactGroupId}
        setSelectedImpactGroupId={setSelectedImpactGroupId}
        impactGroups={impactGroups}
        loading={config?.update?.loading}
        onSave={handleUpdate}
      />

      {contentMetricAssignment?.isOpen && !isImpactGroupRule ? (
        <ContentMetricAssignment
          funnelSteps={funnelSteps}
          isOpen={contentMetricAssignment.isOpen}
          closeSidePanel={contentMetricAssignment.close}
          contentRule={contentMetricAssignment.rule}
        />
      ) : null}
      {isOpenDeleteDialog && (
        <DeleteRule
          isOpen={isOpenDeleteDialog}
          onCancel={() => {
            resetUserChoices();
            closeDeleteDialog();
          }}
          onConfirm={handleDeleteRule}
          loading={loadingDelete}
          rule={rule}
          userChoices={userChoices}
          setUserChoices={setUserChoices}
        />
      )}
    </div>
  );
};

import React, { FC } from 'react';
import { NexoyaFunnelStepV2, NexoyaMeasurement } from '../../../../../../types';
import { usePortfolio } from '../../../../../../context/PortfolioProvider';
import {
  FunnelStepLabelButtonStyled,
  FunnelStepTitleContainerStyled,
  LabelsStyled,
  LabelTitleStyled,
} from '../../../Funnel/styles';
import { capitalizeWords } from '../../../../../../utils/string';
import SvgCheckCircle from '../../../../../../components/icons/CheckCircle';
import { nexyColors } from '../../../../../../theme';
import { cn } from '../../../../../../lib/utils';
import { AssignedMetric } from '../ContentMetricAssignment';
import { getAssignedMetricBasedOnMappingType } from '../utils';

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  getSelectedMeasurementForFunnelStepId: (funnelStepId: number) => Partial<NexoyaMeasurement>;
  assignedMetrics: AssignedMetric[];
  translations: any;
  mergedMeasurements: NexoyaMeasurement[];
}

export const AssignMetricFunnelStepLabel: FC<Props> = ({
  translations,
  funnelSteps,
  assignedMetrics,
  mergedMeasurements,
  getSelectedMeasurementForFunnelStepId,
}) => {
  const {
    selectedFunnelStep: { selectedFunnelStep, setSelectedFunnelStep },
  } = usePortfolio();

  return (
    <LabelsStyled>
      {funnelSteps?.map((funnelStep) => {
        const funnelStepId = funnelStep?.funnelStepId;
        const funnelStepSelected = selectedFunnelStep?.funnel_step_id === funnelStepId;

        const { assignedMetricName, mappingTypeLabel, hasAssigned } = getAssignedMetricBasedOnMappingType({
          funnelStepId: funnelStep.funnelStepId,
          assignedMetrics,
          mergedMeasurements,
          getSelectedMeasurementForFunnelStepId,
          translations,
        });

        return (
          <FunnelStepLabelButtonStyled
            key={funnelStepId}
            active={funnelStepSelected || hasAssigned}
            className={funnelStepSelected ? 'bg-inherit' : hasAssigned ? 'border-l border-primary !bg-white' : ''}
            labelClassName={cn('h-full flex flex-col justify-between', hasAssigned && '!opacity-100')}
            onClick={() =>
              setSelectedFunnelStep({
                title: funnelStep?.title,
                funnel_step_id: funnelStep.funnelStepId,
                type: funnelStep.type,
              })
            }
          >
            <div
              className={cn(
                'absolute left-0 top-0 h-[98px] w-1 rounded-md',
                hasAssigned ? 'bg-green-400' : 'bg-neutral-100',
              )}
            />
            <FunnelStepTitleContainerStyled style={{ marginBottom: 0 }}>
              <LabelTitleStyled className="flex items-center gap-2">
                {capitalizeWords(funnelStep.title)}{' '}
                {hasAssigned ? <SvgCheckCircle style={{ color: nexyColors.greenTeal, width: 14, height: 14 }} /> : null}
              </LabelTitleStyled>
            </FunnelStepTitleContainerStyled>
            <div className="text-sm font-light">
              {hasAssigned ? (
                <div className="text-neutral-800">
                  {assignedMetricName === 'No data' ? 'Multiple custom conversions' : assignedMetricName} <br />
                  <span className="text-xs text-neutral-400">Type: {mappingTypeLabel}</span>
                </div>
              ) : (
                <div className="text-neutral-300">
                  No assignment yet <br />
                </div>
              )}
            </div>
          </FunnelStepLabelButtonStyled>
        );
      })}
    </LabelsStyled>
  );
};

import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CONTENT_RULES_QUERY } from './queryContentRules';
import { useTeam } from '../../context/TeamProvider';

const DELETE_CONTENT_RULE_MUTATION = gql`
  mutation DeleteContentRuleAndUnapplyFromContents(
    $contentRuleId: Float!
    $portfolioId: Float!
    $teamId: Float!
    $userChoices: [DeleteContentRuleUserChoiceInput!]!
  ) {
    deleteContentRuleAndUnapplyFromContents(
      contentRuleId: $contentRuleId
      portfolioId: $portfolioId
      teamId: $teamId
      userChoices: $userChoices
    )
  }
`;

type DeleteContentRuleProps = {
  teamId: number;
  portfolioId: number;
  contentRuleId: number;
  userChoices: {
    applyOtherContentRuleId?: number;
    discoveredContentId: number;
    removeFunnelStepMappings: boolean;
  }[];
};

export function useDeleteContentRuleMutation({ portfolioId }: { portfolioId: number }) {
  const { teamId } = useTeam();

  return useMutation<{ deleteContentRuleAndUnapplyFromContents: boolean }, DeleteContentRuleProps>(
    DELETE_CONTENT_RULE_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: (error) => {
        console.error(error);
        toast.error(error.message);
      },
      onCompleted: () => {
        toast.success('Content rule deleted and unapplied successfully');
      },
      refetchQueries: [
        {
          notifyOnNetworkStatusChange: true,
          query: CONTENT_RULES_QUERY,
          variables: {
            teamId,
            portfolioId,
          },
          fetchPolicy: 'network-only',
        },
      ],
    },
  );
}
